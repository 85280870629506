import React, {Component} from "react";
import {connect} from "react-redux";

class Kartumenu extends Component {

	/*klikKategori = async () => {
		let iduser = reactLocalStorage.get('pengguna');
		switch(this.props.page) {
			case 'Adm' : this.setState({iduser:iduser, kode_kat:'KF004'},
											() => {
												this.cekKategori(this.state.kode_kat);
											}
										);
						 break;
			default:
					alert("Halaman Belum Dibuat");
					return false;
		}
	}

	cekKategori = (kodekat) => {
		let svr = this.props.server + '/akses/res_cekkategori';
		Axios.post(svr, this.state, this.props.kepala).then (
			(response) => {
				if (response.data.kode==='00') {
					switch(kodekat) {
						case 'KF004' : this.props.gantiHalaman('Adm'); break;
						default: alert("Unknown Page");
					}
				}
				else {
					alert("Maaf Anda TIDAK DIIZINKAN mengakses fitur ini\nUntuk informasi lebih lanjut, silahkan menghubungi Pengurus Dirgantara");
				}
			}
		).catch((error) => {
			alert("Terjadi Error: " + error);
			this.setState({status:false});
		});
	}*/

	ikonKlik = (halaman) => {
		this.props.gantiHalaman(halaman);
	}


    render() {
        return(
            <div className="card" onClick={(e)=>this.ikonKlik(this.props.page)}>
                <img src={require('../gbr/' + this.props.gambar)} className="card-img-top" alt="" />
                <div className="card-body">
                    <div className="card-text text-center">
                        <p><strong>{this.props.judul}</strong></p>
                    </div>
                </div>
            </div>
        )
    }
}

const petaGudangkeProp = (state) => {
    return {
        halaman: state.halaman,
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp) (Kartumenu);
