import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from 'axios';
import {connect} from "react-redux";

class Catatbayar extends Component {
	constructor(props) {
		super(props);
		this.state = {dTag:[], sLoad:0, dAng:[], bayar:0};
	}

	isiBayar = (e) => {
		this.setState({bayar:e.target.value})
	}

	daftarTagihan = () => {
		let svr = this.props.server + "/ciuran/res_tagperanggota";
		this.setState({sLoad:1});
		Axios.post(svr, {nomor_anggota:this.props.nomoranggota,
						 bulan_periode:this.props.blnPeriode,
						 thn_periode:this.props.thnPeriode
					 }, this.props.kepala).then((res)=> {
						 if (res.data.kode==='00') {
							 this.setState({sLoad:0, dTag:res.data.isi});
						 }
						 else {
							 this.setState({sLoad:0});
							 alert(res.data.pesan);
							 this.props.gantiHalaman('BayarTagihan');
						 }
					 }).catch((error)=>{
						this.setState({sLoad:0});
						alert("Terjadi Error:\n" + error);
						this.props.gantiHalaman('BayarTagihan');
					 });
	}

	detilAnggota = () => {
		let svr = this.props.server + "/canggota/res_detilanggota";
		this.setState({sLoad:1});
		Axios.post(svr, {nomor_anggota:this.props.nomoranggota}, this.props.kepala).then(
			(res)=>{
				if (res.data.kode==='00') {
					this.setState({dAng:res.data.isi, sLoad:0});
				}
				else {
					this.setState({sLoad:0});
					alert("Terjadi Kesalahan:\n" + res.data.pesan);
					this.props.gantiHalaman('BayarTagihan');
				}
		}).catch((error)=>{
			this.setState({sLoad:0});
			alert("Terjadi Error: \n" + error);
			this.props.gantiHalaman('BayarTagihan');
		})
	}

	componentDidMount() {
		this.daftarTagihan();
		this.detilAnggota();
	}

	render() {
		let vTag;
		let vDetil;
		vTag = this.state.dTag.length > 0 ?
			   <table className="table table-sm">
			   		<tbody>
						<tr>
							<td className="table-info">No. Anggota</td>
							<td className="bg-info text-light" style={{fontSize:"14pt"}}>{this.state.dAng.nomor_anggota}</td>
						</tr>
						<tr>
							<td className="table-info">Call Sign</td>
							<td className="bg-info text-light" style={{fontSize:"14pt"}}><strong>{this.state.dAng.callsign_delta}</strong></td>
						</tr>
						<tr>
							<td className="table-info">Nama</td>
							<td className="bg-info text-light"><strong>{this.state.dAng.nama_lengkap}</strong></td>
						</tr>
					</tbody>
			   </table> : null;

		vDetil =  this.state.dTag.length > 0 ? this.state.dTag.map((vt, index) =>
				   <table className="table table-sm" key={index}>
				   		<tbody>
							<tr>
								<td className="table-danger">Jns. Iuran:</td>
								<td className="bg-danger text-white" style={{fontSize:"14pt"}}>{vt.deskripsi}</td>
							</tr>
							<tr>
								<td className="table-danger">Periode:</td>
								<td className="bg-danger text-white" style={{fontSize:"14pt"}}>{vt.periode_iuran_d}</td>
							</tr>
							<tr>
								<td className="table-danger">Jlh. Tagihan:</td>
								<td className="bg-danger text-white text-right" style={{fontSize:"14pt"}}>{vt.jlh_tagihan}</td>
							</tr>
							<tr>
								<td className="table-danger">Total Tunggakan:</td>
								<td className="bg-danger text-white text-right" style={{fontSize:"14pt"}}>{vt.total_tagihan}</td>
							</tr>
							<tr>
								<td className="table-danger">Jatuh Tempo:</td>
								<td className="bg-danger text-white" style={{fontSize:"14pt"}}>{vt.tgl_tagihan_d}</td>
							</tr>
							<tr>
								<td className="table-success">Jlh. Pembayaran:</td>
								<td className="bg-success">
									<input type="number" className="form-control text-right" style={{fontSize:"16pt"}} defaultValue={vt.total_tagihan} onChange={(e)=>this.isiBayar(e)}/>
								</td>
							</tr>
						</tbody>
				   </table>
			   ) : null;

		return (
			<div className="container-fluid">
				<Subjudul judul="Catat Pembayaran Anggota" page="BayarTagihan" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						{vTag}
						{vDetil}
					</div>
				</div>

				{this.state.sLoad===1 ?
				 <div className="row justify-content-center mt-3">
				 	<div className="col-5 text-center">
						<div className="spinner-border text-primary">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				 </div> : null
			    }
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala,
		nomoranggota: state.nomoranggota,
		blnPeriode:state.bulanPeriode,
		thnPeriode:state.tahunPeriode
    }
}

const petaAksikeProp = (aksi) => {
    return {
		isiNomorAnggota: (noAng) => aksi({type:'ISI_NO_ANGGOTA', payload:{nomoranggota:noAng}}),
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Catatbayar);
