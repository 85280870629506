import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from "axios";
import {connect} from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';
import Logoorari from '../gbr/logoorari.jpg';
import Logorapi from '../gbr/logorapi.png';
import Logodelta from '../gbr/logo.jpeg';

class Detilcallbook extends Component {
	constructor(props) {
		super(props);
		this.state = {detilAng:'', foto:''};
	}

	getDetil = () => {
		let svr = this.props.server + "/canggota/res_detilanggota";
		Axios.post(svr, {nomor_anggota:this.props.nomoranggota}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({detilAng:res.data.isi});
					svr = this.props.server + "/canggota/res_ambilfoto";
					Axios.post(svr, {nomor_anggota:this.props.nomoranggota, useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
						(res)=>{
							if (res.data.kode==='00') {
								this.setState({foto:res.data.isi});
							}
							else {
								alert("Terjadi Kesalahan:\n" + res.data.pesan);
								this.props.gantiHalaman('Anggota');
							}
						}
					).catch((error) => {
						alert("Terjadi Error: \n" + error);
						this.props.gantiHalaman('Anggota');
					})
				}
				else {
					alert("Terjadi Kesalahan: \n" + res.data.pesan);
					this.props.gantiHalaman('Anggota');
				}
			}
		).catch((error) => {
			alert("Terjadi ERROR: \n" + error);
		})
	}

	componentDidMount() {
		this.getDetil();
	}

	render() {
		return (
			<div className="container-fluid">
				<Subjudul judul="Detil Callbook" page="Callbook" />
				<div className="row justify-content-center mt-3">
					<div className="col-12">
						<table className="table table-sm">
							<tbody>
								<tr>
									<td className="table-primary">Nomor Anggota:</td>
									<td className="bg-info text-light">{this.state.detilAng.nomor_anggota}</td>
								</tr>
								<tr>
									<td className="table-primary">Nama Lengkap:</td>
									<td className="bg-info text-light">{this.state.detilAng.nama_lengkap}</td>
								</tr>
								<tr>
									<td className="table-primary">Jenis Kelamin:</td>
									<td className="bg-info text-light">{this.state.detilAng.jenis_kelamin_d}</td>
								</tr>

								<tr>
									<td className="table-primary">Alamat:</td>
									<td className="bg-info text-light">{this.state.detilAng.alamat}</td>
								</tr>
								<tr>
									<td className="table-primary">Kota Domisili:</td>
									<td className="bg-info text-light">{this.state.detilAng.kota}</td>
								</tr>
								<tr>
									<td className="table-primary">Callsign Delta <img src={Logodelta}  alt="Logo Delta" /></td>
									<td className="bg-info text-light">{this.state.detilAng.callsign_delta}</td>
								</tr>
								<tr>
									<td className="table-primary">Callsign Orari <img src={Logoorari}  alt="Logo Delta" /></td>
									<td className="bg-info text-light">{this.state.detilAng.callsign_orari}</td>
								</tr>
								<tr>
									<td className="table-primary">Callsign RAPI <img src={Logorapi}  alt="Logo Delta" /></td>
									<td className="bg-info text-light">{this.state.detilAng.callsign_rapi}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="row justify-content-center">
						<div className="col-10">
							<img src={this.state.foto} className="img-fluid"  alt="Foto" />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
	return {
		server: state.server,
		kepala: state.kepala,
		halaman: state.halaman,
		nomoranggota:state.nomoranggota
	}
}

const petaAksikeProp = (aksi) => {
	return {
		gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}})
	}
}

export default connect(petaGudangkeProp, petaAksikeProp)(Detilcallbook);
