import React, {Component} from "react";
import {connect} from "react-redux";
import Login from "./login";
import Gantipassdefa from "./gantipassdefa";
import Menuutama from "./menuutama";
import Adm from './adm';
import Tambahpengguna from './tambahpengguna';
import Koreksiuser from './koreksiuser';
import Frmkoreksi from './frmkoreksi';
import Hapususer from './hapususer';
import Frmhapususer from './frmhapususer';
import Daftaruser from './daftaruser';
import Detiluser from './detiluser';
import Hakakses from './hakakses';
import Akseskategori from './akseskategori';
import Aksesfitur from './aksesfitur';
import Resetpassword from './resetpassword';
import Gantipassword from './gantipassword';
import Aktifnon from './aktifnon';
import Anggota from './anggota';
import Tambahanggota from './tambahanggota';
import Daftaranggota from './daftaranggota';
import Detilanggota from './detilanggota';
import Koreksianggota from './koreksianggota';
import Frmkoranggota from './frmkoranggota';
import Keluarga from './keluarga';
import Frmkeluarga from './frmkeluarga';
import Hapusanggota from './hapusanggota';
import Iuran from './iuran';
import Jenisiuran from './jenisiuran';
import Tambahiuran from './tambahiuran';
import Koriuran from './koriuran';
import Frmkoriuran from './frmkoriuran';
import Infoiuran from './infoiuran';
import Detiliuran from './detiliuran';
import Hapusiuran from './hapusiuran';
import Tglawalbayar from './tglawalbayar';
import Anggratis from './anggratis';
import Bayariuran from './bayariuran';
import Buattagihan from './buattagihan';
import Bayartagihan from './bayartagihan';
import Catatbayar from './catatbayar';
import Callbook from './callbook';
import Detilcallbook from './detilcallbook';
import {reactLocalStorage} from 'reactjs-localstorage';

class Utama extends Component {

    render() {
        //console.log(reactLocalStorage.get('pengguna'));
        if (reactLocalStorage.get('pengguna')===null || reactLocalStorage.get('pengguna')===undefined) {
            return (
                <Login />
            );
        }
        else {
            switch(this.props.halaman) {
                case 'GantiPassDefa' :
                            return (
                                <Gantipassdefa />
                            )
                case 'Menuutama' :
                            return (
                                <Menuutama />
                            )
                case 'Adm' :
                            return (
                                <Adm />
                            )
                case 'TambahPengguna' :
                            return (
                                <Tambahpengguna />
                            )
				case 'KoreksiPengguna':
						  return (
							  <Koreksiuser />
						  );
				case 'FormKoreksi' :
						return (
							<Frmkoreksi />
						)
				case 'HapusPengguna' :
						return (
							<Hapususer />
						)
				case 'FormHapus' :
						return (
							<Frmhapususer />
						)
                case 'DaftarPengguna' :
                            return (
                                <Daftaruser />
                            )
				case 'DetilPengguna' :
					 	return (
							<Detiluser />
						)
                case 'HakAkses' :
                    return (
                        <Hakakses />
                    )
				case 'AksesKategori' :
					return (
						<Akseskategori />
					)
				case 'AksesFitur' :
					return (
						<Aksesfitur />
					)
				case 'ResetPassword' :
					return (
						<Resetpassword />
					)
				case 'GantiPassword' :
					return (
						<Gantipassword />
					)
				case 'StatusAktif' :
					return (
						<Aktifnon />
					)
				case 'Anggota' :
					return (
						<Anggota />
					)
				case 'TambahAnggota' :
					return (
						<Tambahanggota />
					)
				case 'DaftarAnggota' :
					return (
						<Daftaranggota />
					)
				case 'DetilAnggota' :
					return (
						<Detilanggota />
					)
				case 'KoreksiAnggota' :
					return (
						<Koreksianggota />
					)
				case 'FrmKorAnggota' :
					return (
						<Frmkoranggota />
					)
				case 'Keluarga' :
					return (
						<Keluarga />
					)
				case 'FormKeluarga' :
					return (
						<Frmkeluarga />
					)
				case 'HapusAnggota' :
					return (
						<Hapusanggota />
					)
				case 'Iuran':
					return (
						<Iuran />
					)
				case 'JenisIuran' :
					return (
						<Jenisiuran />
					)
				case 'TambahIuran':
					return (
						<Tambahiuran />
					)
				case 'KoreksiIuran' :
					return (
						<Koriuran />
					)
				case 'FormKorIuran' :
					return (
						<Frmkoriuran />
					)
				case 'InfoIuran':
					return (
						<Infoiuran />
					)
				case 'DetilIuran' :
					return (
						<Detiliuran />
					)
				case 'HapusIuran' :
					return (
						<Hapusiuran />
					)
				case 'TglAwalBayar' :
					return (
						<Tglawalbayar />
					)
				case 'AnggotaGratis' :
					return (
						<Anggratis />
					)
				case 'BayarIuran' :
					return (
						<Bayariuran />
					)
				case 'BuatTagihan' :
					return (
						<Buattagihan />
					)
				case 'BayarTagihan' :
					return (
						<Bayartagihan />
					)
				case 'CatatBayar' :
					return (
						<Catatbayar />
					)
				case 'Callbook':
					return (
						<Callbook />
					)
				case 'DetilCB' :
					return (
						<Detilcallbook />
					)
                default:
                return(
                    <Menuutama />
                )
            }
        }
    }
}

const petaGudangkeProp = (state) => {
    return {
        idkonek : state.konek,
        idpengguna : state.id,
        server: state.server,
        kepala: state.kepala,
        halaman: state.halaman
    }
}

const petaAksikeProp = (aksi) => {
    return {
        bacaStatus: () => aksi({type:'BACA_STATUS'})
    }
}
export default connect(petaGudangkeProp, petaAksikeProp)(Utama);
