import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from 'axios';
import {connect} from "react-redux";

class Koreksiuser extends Component {
	constructor(props) {
		super(props);
		this.state = {dUser:[], cari:'', ada:0, tampil:'false'};
	}

	isiDataUser = (kolom, e) => {
		this.setState(
			{[kolom] : e.target.value}
		);
	}

	cariUser = () => {
		let svr = this.props.server + "/cpengguna/res_caripengguna";
		this.setState({ada:0});
		Axios.post(svr,{cari:this.state.cari}, this.props.kepala).then(
			(response) => {
				if (response.data.kode==='00') {
					this.setState({dUser:response.data.isi, ada:1});
				}
				else {
					alert("DATA TIDAK DITEMUKAN...");
				}
			}
		).catch((error)=> {
			alert("Terjadi Error: ", error);
		});
	}

	bukaForm = (kodeuser) => {
		this.props.isiKodeUser(kodeuser);
		this.props.gantiHalaman('FormKoreksi');
	}

	render() {
		let vdaftar;

		if (this.state.ada===1) {
			vdaftar =
			<div className="row justify-content-center mt-2">
				<div className="col-12">
					<ul className="list-group list-group-flush">
						{this.state.dUser.map((du, index) =>
							<li className="list-group-item" key={index}>
								<p>Login		:	<strong>{du.kode}</strong></p>
								<p>Nama Lengkap	:	<strong>{du.nama_lengkap}</strong></p>
								<p className="text-right"><button className="btn btn-warning" onClick={(e)=>this.bukaForm(du.kode)}>Koreksi</button></p>
							</li>
						)}
					</ul>
				</div>
			</div>
		}
		else {
			vdaftar = null;
		}
		return (
				<div className="container-fluid">
					<Subjudul judul="Koreksi Data Pengguna" page="Adm"/>
					<div className="row justify-content-center mt-5">
						<div className="col-12">
							<div className="input-group">
								<input type="text" maxLength="50" className="form-control" placeholder="Ketik Kode Login atau Nama Pengguna" onChange={(e)=>this.isiDataUser('cari',e)}/>
								<div className="input-group-append">
									<button className="btn btn-info" onClick={this.cariUser}>Cari</button>
								</div>
							</div>
						</div>
					</div>
					{vdaftar}
				</div>

		);
	}
}

const petaGudangkeProp = (state) => {
    return {
        halaman: state.halaman,
        server: state.server,
        kepala: state.kepala,
    }
}

const petaAksikeProp = (aksi) => {
    return {
		isiKodeUser : (kodeuser) => aksi({type:'ISI_KODEUSER', payload:{kodeuser:kodeuser}}),
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Koreksiuser);
