import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";

class Detiliuran extends Component {
	constructor(props) {
		super(props);
		this.state = {dIuran:[]};
	}

	isiDetil = () => {
		let svr = this.props.server + "/ciuran/res_detiliuran";
		Axios.post(svr, {kode:this.props.kodeiuran}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dIuran:res.data.isi});
				}
				else {
					alert("Terjadi Kesalahan Detil:\n" + res.data.pesan);
					this.props.gantiHalaman('InfoIuran');
				}
			}
		).catch((error)=> {
			alert("Terjadi Error Detil: \n" + error);
		});
	}

	componentDidMount() {
		this.isiDetil();
	}

	render() {
		return (
			<div className="container-fluid">
				<Subjudul judul="Detil Iuran" page="InfoIuran" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<table className="table table-sm">
							<tbody>
								<tr>
									<td className="table-info">Kode:</td>
									<td className="bg-warning"><strong>{this.state.dIuran.kode}</strong></td>
								</tr>
								<tr>
									<td className="table-info">Deskripsi:</td>
									<td className="bg-warning"><strong>{this.state.dIuran.deskripsi}</strong></td>
								</tr>
								<tr>
									<td className="table-info">Tarif:</td>
									<td className="bg-warning text-right"><strong>Rp. {this.state.dIuran.tarif}</strong></td>
								</tr>
								<tr>
									<td className="table-info">Periode:</td>
									<td className="bg-warning"><strong>{this.state.dIuran.periode_d}</strong></td>
								</tr>
								<tr>
									<td className="table-info">Waktu Entry:</td>
									<td className="bg-warning"><strong>{this.state.dIuran.waktu_input}</strong></td>
								</tr>
								<tr>
									<td className="table-info">Petugas Entry:</td>
									<td className="bg-warning"><strong>{this.state.dIuran.user_input}</strong></td>
								</tr>
								<tr>
									<td className="table-info">Waktu Koreksi:</td>
									<td className="bg-warning"><strong>{this.state.dIuran.waktu_edit}</strong></td>
								</tr>
								<tr>
									<td className="table-info">Petugas Koreksi:</td>
									<td className="bg-warning"><strong>{this.state.dIuran.user_edit}</strong></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala,
		kodeiuran:state.kodeiuran
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}})
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Detiliuran);
