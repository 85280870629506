import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from "axios";
import {connect} from "react-redux";

class Daftaranggota extends Component {
	constructor(props) {
		super(props);
		this.state = {dAng:[], cari:''};
	}

	isiDaftar = () => {
		let svr = this.props.server + "/canggota/res_daftaranggota";
		Axios.post(svr, {cari:null}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dAng:res.data.isi});
				}
				else {
					alert("Terjadi Kesalahan:\n" + res.data.pesan);
				}
			}
		).catch((error)=> {
			alert("Terjadi Error:\n" + error);
		});
	}

	isiCari = (e) => {
		this.setState({cari:e.target.value});
	}

	cariAnggota = () =>  {
		let svr = this.props.server + "/canggota/res_daftaranggota";
		Axios.post(svr, {cari:this.state.cari}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dAng:res.data.isi});
				}
				else {
					this.setState({dAng:[]});
				}
			}
		).catch((error) => {
			alert("Terjadi Error:\n" + error);
		});
	}

	detilAnggota = (noangg) => {
		this.props.isinomoranggota(noangg);
		this.props.gantiHalaman('DetilAnggota');
	}

	componentDidMount() {
		this.isiDaftar();
	}

	render() {
		return (
			<div className="container-fluid">
				<Subjudul judul="Daftar Anggota" page="Anggota" />
				<div className="row justify-content-center mt-3 mb-3">
					<div className="col-12">
						<div className="input-group">
							<input type="text" className="form-control" placeholder="Call Sign atau Nama" onChange={(e)=>this.isiCari(e)}/>
							<div className="input-group-append">
								<button className="btn btn-primary" onClick={this.cariAnggota}>Cari</button>
							</div>
						</div>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-12">
						<ul className="list-group list-group-flush">
						{this.state.dAng.map((da, index) =>
							<li className="list-group-item" key={index}>
								<table className="table table-sm">
									<tbody>
										<tr>
											<td className="bg-success">No. Anggota:</td>
											<td className="bg-info" style={{fontSize:"14pt"}}>{da.nomor_anggota}</td>
										</tr>
										<tr>
											<td className="bg-success">Call Sign Delta:</td>
											<td className="bg-warning" style={{fontSize:"18pt"}}>{da.callsign_delta}</td>
										</tr>
										<tr>
											<td className="bg-success">Nama Lengkap:</td>
											<td className="bg-light" style={{fontSize:"14pt"}}>{da.nama_lengkap}</td>
										</tr>
										<tr className="bg-light">
											<td colSpan="2" className="text-right">
												<button className="btn btn-primary" onClick={(e)=>this.detilAnggota(da.nomor_anggota)}>Detil</button>
											</td>
										</tr>
									</tbody>
								</table>
							</li>
						)}
						</ul>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
	return {
		server: state.server,
		kepala: state.kepala,
		halaman: state.halaman
	}
}

const petaAksikeProp = (aksi) => {
	return {
		isinomoranggota: (noangg) => aksi({type:'ISI_NO_ANGGOTA', payload:{nomoranggota:noangg}}),
		gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}})
	}
}

export default connect(petaGudangkeProp, petaAksikeProp)(Daftaranggota);
