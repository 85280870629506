import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
//import {reactLocalStorage} from 'reactjs-localstorage';

class Koriuran extends Component {
	constructor(props) {
		super(props);
		this.state={cari:'', dft:[]};
	}

	isiCari = (e) => {
		this.setState({cari:e.target.value});
	}

	doCari = () => {
		let svr = this.props.server + "/ciuran/res_daftariuran/";
		Axios.post(svr, {cari:this.state.cari}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dft:res.data.isi});
				}
				else {
					alert("Terjadi Kesalahan:\n" + res.data.pesan);
				}
			}
		).catch((error) => {
			alert("Terjadi Error: \n" + error);
		});
	}

	bukaForm = (kode) => {
		this.props.isiKodeIuran(kode);
		this.props.gantiHalaman('FormKorIuran');
	}

	render() {
		let vdaftar;

		vdaftar = this.state.dft.length > 0 ?
				  <div className="row justify-content-center mt-3">
				  	<div className="col-12">
						<h5>Daftar Iuran</h5>
						{this.state.dft.map((di, index)=>
							<table className="table table-sm mb-3" key={index}>
								<tbody>
									<tr>
										<td className="table-info">Kode:</td>
										<td className="bg-info text-light">{di.kode}</td>
									</tr>
									<tr>
										<td className="table-info">Nama Iuran:</td>
										<td className="bg-info text-light">{di.deskripsi}</td>
									</tr>
									<tr>
										<td className="table-info">Tarif:</td>
										<td className="bg-info text-light text-right">Rp. {di.tarif}</td>
									</tr>
									<tr>
										<td colSpan="2" className="text-right">
											<button className="btn btn-warning" onClick={(e)=>this.bukaForm(di.kode)}>Koreksi</button>
										</td>
									</tr>
								</tbody>
							</table>
						)}
					</div>
				  </div> : null;

		return (
			<div className="container-fluid">
				<Subjudul judul="Koreksi Iuran" page="JenisIuran" />
				<div className="row justify-content-center mt-3">
					<div className="col-12">
						<div className="input-group">
							<input type="text" className="form-control" placeholder="Deskripsi Iuran" onChange={(e)=>this.isiCari(e)}/>
							<div className="input-group-append">
								<button className="btn btn-primary" onClick={this.doCari}>Cari</button>
							</div>
						</div>
					</div>
				</div>
				{vdaftar}
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
		isiKodeIuran: (kode) => aksi({type:'ISI_KODE_IURAN', payload:{kodeiuran:kode}}),
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Koriuran);
