import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

class Tambahiuran extends Component {
	constructor(props) {
		super(props);
		this.state={deskripsi:'',
					tarif:0,
					periode:'',
					dPer:[]
				   };
	}

	isiForm = (isian, e) => {
		this.setState({[isian]:e.target.value});
	}

	listPeriode = () => {
		let svr = this.props.server + "/ciuran/res_daftarperiode";
		Axios.get(svr).then(
			(res)=>{
				if (res.data.kode==='00') {
					this.setState({dPer:res.data.isi});
				}
				else {
					alert("Belum Ada Periode Iuran yang Terdaftar");
					this.props.gantiHalaman('Iuran');
				}
			}
		).catch((error)=>{
			alert("Terjadi Error Daftar Periode: \n" + error);
		})
	}

	simpanJenis = () => {
		let svr = this.props.server + '/ciuran/res_tambahiuran';
		var konfir = window.confirm("Apakah Data Iuran Sudah Benar?");
		if (konfir) {
			Axios.post(svr, {deskripsi:this.state.deskripsi,
							 tarif:this.state.tarif,
							 periode:this.state.periode,
							 useraktif:reactLocalStorage.get('pengguna')
						 }, this.props.kepala).then(
							 (res) => {
								 if (res.data.kode==='00') {
									 alert("Penambahan Data Jenis Iuran BERHASIL");
									 this.props.gantiHalaman('Iuran');
								 }
								 else {
									 alert("Terjadi Kesalahan:\n" + res.data.pesan);
								 }
							 }
						 ).catch((error) => {
							 alert("Terjadi Error:\n" + error);
						 })

		}
	}

	componentDidMount() {
		this.listPeriode();
	}

	render() {
		return (
			<div className="container-fluid">
				<Subjudul judul="Tambah Iuran Baru" page="JenisIuran" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="form-group">
							<label htmlFor="deskripsi">Nama/Deskripsi Iuran:</label>
							<input type="text" id="deskripsi" className="form-control" maxLength="45" onChange={(e)=>this.isiForm('deskripsi', e)}/>
						</div>
						<div className="form-group">
							<label htmlFor="tarif">Tarif:</label>
							<div className="input-group">
								<div className="input-group-prepend">
									<span className="input-group-text">Rp.</span>
								</div>
								<input type="text" id="tarif" className="form-control text-right" value={this.state.tarif}  onChange={(e)=>this.isiForm('tarif', e)}/>
								<p className="text-info"><small><em>Ketik tanpa titik ribuan atau koma desimal (hanya angka)</em></small></p>
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="periode">Periode:</label>
							<select className="form-control" id="periode" onChange={(e)=>this.isiForm('periode', e)}>
								{this.state.dPer.map((dp, index)=>
									<option key={index} value={dp.kode}>{dp.deskripsi}</option>
								)}
							</select>
						</div>
						<div className="form-group text-right">
							<button className="btn btn-secondary mr-2" onClick={(e)=>this.props.gantiHalaman('JenisIuran')}>Batal</button>
							<button className="btn btn-primary" onClick={this.simpanJenis}>Simpan</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Tambahiuran);
