import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import {reactLocalStorage} from 'reactjs-localstorage';
import * as serviceWorker from './serviceWorker';

const satkomState = {
    id : null,
    konek : false,
    //server: 'http://localhost/server/svr_satkom',
    server: 'http://svr.labpiranti.com/svr_satkom',
    kepala: {headers: {'Content-Type': 'application/x-www-form-urlencoded'}},
    halaman: null,
	kodeuser: '',
	nomoranggota: '',
	kodeiuran:'',
	bulanPeriode:0,
	tahunPeriode:0
}

//Reducer
const satkomReducer = (state = satkomState, aksi) => {
    const idpengguna = reactLocalStorage.get('pengguna');
    switch(aksi.type) {
        case 'BACA_STATUS':
            console.log('idpengguna: ', idpengguna);
            if (idpengguna==null) {
                return {...state,
                            konek:false,
                            id: null,
                            halaman:'LOGIN'
                        }
            }
            else {
                    return {...state,
                                konek:true,
                                id:idpengguna,
                                halaman:'Menuutama'
                            }
            }
        case 'GANTI_HALAMAN' :
            return {
                ...state,
                halaman: aksi.payload.halaman
            }
        case 'RESET_STATUS' :
            localStorage.clear();
            return {
                ...state,
                konek:false,
                id:null,
                halaman:null
            }
        case 'GANTI_PASSDEFA' :
			reactLocalStorage.set('pengguna',aksi.payload.iduser);
            return {
                ...state,
                id: aksi.payload.iduser
            }
        case 'LOGIN_SUKSES' :
            reactLocalStorage.set('pengguna',aksi.payload.datalogin.kode);
            reactLocalStorage.set('nama', aksi.payload.datalogin.nama);
            return {
                ...state,
                konek: true,
                id: reactLocalStorage.get('pengguna')
            }
		case 'ISI_KODEUSER' :
			return {
				...state,
				kodeuser: aksi.payload.kodeuser
			}
		case 'ISI_NO_ANGGOTA' :
			return {
				...state,
				nomoranggota: aksi.payload.nomoranggota
			}
		case 'ISI_KODE_IURAN' :
			return {
				...state,
				kodeiuran: aksi.payload.kodeiuran
			}
		case 'ISI_PERIODE_TAGIHAN' :
			return {
				...state,
				bulanPeriode: aksi.payload.bulanPeriode,
				tahunPeriode: aksi.payload.tahunPeriode
			}
        case 'LOGOUT_SUKSES' :
            reactLocalStorage.clear();
            return {
                ...state,
                id: null,
                konek:false
            }
        default: return state;
    }
}

//Store
const satkomGudang = createStore(satkomReducer);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={satkomGudang}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();
