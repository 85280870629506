import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from "axios";
import {connect} from "react-redux";

class Keluarga extends Component {
	constructor(props) {
		super(props);
		this.state = {cari:'', dAngg:[], dKel:[]};
	}

	isiCari = (e) => {
		this.setState({cari:e.target.value});
	}

	doCari = () => {
		let svr = this.props.server + "/canggota/res_carianggota";
		Axios.post(svr, {cari:this.state.cari}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dAngg:res.data.isi});
				}
				else {
					alert("Data Tidak Ditemukan");
				}
			}
		).catch((error) => {
			alert("Terjadi Error: \n" + error);
		})
	}

	daftarKeluarga = (noAng) => {
		let svr = this.props.server + "/ckeluarga/get_familylist";
		this.setState({dKel:[]});
		Axios.post(svr, {nomor_anggota:noAng}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dKel:res.data.isi});
				}
				else {
					alert("Belum Ada Anggota Keluarga Yang Terdaftar");
				}
			}
		).catch((error)=>{
			alert("Terjadi Error:\n" + error);
		});
	}

	tampilForm = (noAngg) => {
		this.props.isinomoranggota(noAngg);
		this.props.gantiHalaman('FormKeluarga');
	}

	tutupDaftar = () => {
		this.setState({dKel:[]});
	}

	render() {
		let vdaftar;
		let vdafkel;

		vdafkel = this.state.dKel.length <= 0 ? null :
				  <div className="row justify-content-center mt-3">
				  		<div className="col-12">
							<h5><strong>Daftar Anggota Keluarga</strong></h5>
							<table className="table table-striped table-sm">
								<thead>
									<tr className="bg-dark text-light">
										<td>No.</td>
										<td>Nama Lengkap</td>
										<td>Jns.Kelamin</td>
										<td>Status</td>
									</tr>
								</thead>
								<tbody>
									{this.state.dKel.map((dk, index)=>
										<tr key={index}>
											<td>{index+1}.</td>
											<td>{dk.nama_lengkap}</td>
											<td>{dk.jenis_kelamin_d}</td>
											<td>{dk.kode_status_d}</td>
										</tr>
									)}
									<tr>
										<td colSpan="4" className="text-right">
											<button className="btn btn-secondary" onClick={this.tutupDaftar}>Tutup</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
				  </div>

		if (this.state.dAngg.length > 0) {
			vdaftar = <div className="row justify-content-center mt-3">
					  	<div className="col-12">
							{this.state.dAngg.map((da, index) =>
								<table className="table table-sm mb-2" key={index}>
									<tbody>
										<tr>
											<td className="table-primary">No. Anggota</td>
											<td className="bg-info text-light" style={{fontSize:"14pt"}}>{da.nomor_anggota}</td>
										</tr>
										<tr>
											<td className="table-primary">Call Sign Delta</td>
											<td className="bg-info text-light" style={{fontSize:"14pt"}}><strong>{da.callsign_delta}</strong></td>
										</tr>
										<tr>
											<td className="table-primary">Call Sign Delta</td>
											<td className="bg-info text-light"><strong>{da.nama_lengkap}</strong></td>
										</tr>
										<tr>
											<td colSpan="2" className="text-right">
												<button className="btn btn-info mr-2" onClick={(e)=>this.daftarKeluarga(da.nomor_anggota)}>Lihat Keluarga</button>
												<button className="btn btn-success" onClick={(e)=>this.tampilForm(da.nomor_anggota)}>Kelola Keluarga</button>
											</td>
										</tr>
									</tbody>
								</table>
							)}
						</div>
					  </div>
		}
		else {
			vdaftar = null;
		}

		return (
			<div className="container-fluid">
				<Subjudul judul="Keluarga" page="Anggota" />
				<div className="justify-content-center mt-5">
					<div className="col-12">
						<div className="input-group">
							<input type="text" className="form-control" placeholder="No. Anggota/CallSign Delta/Nama" onChange={(e)=>this.isiCari(e)}/>
							<div className="input-group-append">
								<button className="btn btn-primary" onClick={this.doCari}>Cari</button>
							</div>
						</div>
					</div>
				</div>
				{vdaftar}
				{vdafkel}
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
		isinomoranggota: (noangg) => aksi({type:'ISI_NO_ANGGOTA', payload:{nomoranggota:noangg}}),
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}})
    }
}
export default connect(petaGudangkeProp, petaAksikeProp)(Keluarga);
