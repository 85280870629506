import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import Logoorari from '../gbr/logoorari.jpg';
import Logorapi from '../gbr/logorapi.png';
import Logodelta from '../gbr/dirgantara192.png';
import {reactLocalStorage} from 'reactjs-localstorage';

class Tambahanggota extends Component {
	constructor(props) {
		super(props);
		this.state = {nomorAnggota:'',
					  namaLengkap:'',
					  jenisKelamin:'',
					  tempatLahir:'',
					  tglLahir:'',
					  alamat:'',
					  kota:'',
					  mobile:'',
					  nomorKTP:'',
					  nomorKK:'',
					  csDirgantara:'',
					  csOrari:'',
					  csRapi:'',
					  ayahkd:'',
					  ibukd:'',
					  ayahmr:'',
					  ibumr:'',
					  foto:''
					 }
	}

	isiData = (kolom, e) => {
		this.setState({[kolom]:e.target.value});
	}

	uploadFoto = (e) => {
		let svr = this.props.server + "/canggota/terima_uploadfoto";
		let vnamafile = e.target.files[0].name;
		let ekstensi = vnamafile.substr(vnamafile.length - 3);

		var fdata = new FormData();
		this.setState({foto: 'tmp_' + reactLocalStorage.get('pengguna') + '_.' + ekstensi});
		fdata.append('berkas', e.target.files[0], e.target.files[0].name);
		fdata.append('nama_file', 'tmp_' + reactLocalStorage.get('pengguna') + '_.' + ekstensi);
		/*var konfig = {onUploadProgress: function(progressEvent) {
					  	var persenKomplit = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					  	this.setState({vsprogress:'width:' + persenKomplit, vnprogress:persenKomplit});
				  	 },
					 headers: {//'Content-Type': 'multipart/form-data',
					 		   'Access-Control-Allow-Origin': '*',
							   'Access-Control-Allow-Headers' : 'content-type, content-length',
					 	   	   'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
							   'Access-Control-Allow-Credentials': 'false',
							   //'Access-Control-Request-Headers': 'content-type'
				 	  		  }
					};

		Axios.post(svr, fdata, konfig).then(
			(response)=>{
				console.log(response);
			}
		).catch((error)=>{
			alert("Terjadi Error:\n", error);
		});*/
		Axios.post(svr, fdata, {}).then(
			(res) => {
				if (res.data.kode==='00') {
					alert(res.data.pesan);
				}
			}
		).catch((error) => {
			alert("Terjadi Error:\n", error);
		});
	}

	simpanData = () => {
		let svr = this.props.server + "/canggota/res_tbhanggota";
		let vdata = {nomor_anggota:this.state.nomorAnggota,
					 nama_lengkap:this.state.namaLengkap,
					 jenis_kelamin:this.state.jenisKelamin,
					 tempat_lahir:this.state.tempatLahir,
					 tgl_lahir:this.state.tglLahir,
					 alamat:this.state.alamat,
					 kota:this.state.kota,
					 mobile:this.state.mobile,
					 nomor_ktp:this.state.nomorKTP,
					 nomor_kk:this.state.nomorKK,
					 cs_dirgantara:this.state.csDirgantara,
					 cs_orari:this.state.csOrari,
					 cs_rapi:this.state.csRapi,
					 ayah_kd:this.state.ayahkd,
					 ibu_kd:this.state.ibukd,
					 ayah_mr:this.state.ayahmr,
					 ibu_mr:this.state.ibumr,
					 foto:this.state.foto,
					 useraktif:reactLocalStorage.get('pengguna')
					};
		var konfir = window.confirm("Apakah Data Sudah Benar?");
		if (konfir) {
			Axios.post(svr, vdata, this.props.kepala).then(
				(res) => {
					if (res.data.kode==='00') {
						alert("Data Anggota BERHASIL DITAMBAH");
						this.props.gantiHalaman('Anggota');
					}
					else {
						alert("Terjadi Kesalahan: \n" + res.data.pesan);
					}
				}
			).catch((error) => {
				alert("Terjadi Error: \n", error);
			})
		}
	}

	render() {

		return (
			<div className="container-fluid">
				<Subjudul judul="Tambah Anggota Baru" page="Anggota" />
				<div className="row justify-content-center mt-3">
					<div className="col-12">
						<div className="form-group">
							<label forhtml="nomoranggota">Nomor Anggota:</label>
							<input type="text" className="form-control" id="nomoranggota" onChange={(e)=>this.isiData('nomorAnggota', e)} />
						</div>
						<div className="form-group">
							<label forhtml="namalengkap">Nama Lengkap:</label>
							<input type="text" className="form-control" id="namalengkap" onChange={(e)=>this.isiData('namaLengkap', e)}/>
						</div>
						<div className="form-group">
							<label forhtml="jeniskelamin">Jenis Kelamin:</label>
							<select className="form-control" id="jeniskelamin" onChange={(e)=>this.isiData('jenisKelamin', e)}>
								<option value="null">&nbsp;</option>
								<option value="L">Laki-Laki</option>
								<option value="P">Perempuan</option>
							</select>
						</div>
						<div className="form-group">
							<label forhtml="tptlahir">Tempat Lahir:</label>
							<input type="text" className="form-control" id="tptlahir" onChange={(e)=>this.isiData('tempatLahir', e)} />
						</div>
						<div className="form-group">
							<label forhtml="tgllahir">Tanggal Lahir:</label>
							<input type="date" className="form-control" id="tgllahir" onChange={(e)=>this.isiData('tglLahir', e)} />
						</div>
						<div className="form-group">
							<label forhtml="alamat">Alamat:</label>
							<input type="text" className="form-control" id="alamat" onChange={(e)=>this.isiData('alamat', e)}/>
						</div>
						<div className="form-group">
							<label forhtml="kota">Kota Domisili:</label>
							<input type="text" className="form-control" id="kota" onChange={(e)=>this.isiData('kota', e)}/>
						</div>
						<div className="form-group">
							<label forhtml="mobile">Nomor HP/WA:</label>
							<input type="text" className="form-control" id="mobile" onChange={(e)=>this.isiData('mobile', e)} />
						</div>
						<div className="form-group">
							<label forhtml="ktp">Nomor KTP:</label>
							<input type="text" className="form-control" id="ktp" onChange={(e)=>this.isiData('nomorKTP', e)}/>
						</div>
						<div className="form-group">
							<label forhtml="nokk">Nomor Kartu Keluarga:</label>
							<input type="text" className="form-control" id="nokk" onChange={(e)=>this.isiData('nomorKK', e)}/>
						</div>
						<div className="form-group">
							<label forhtml="csdelta">Callsign Dirgantara <img src={Logodelta} width="10%" height="10%" alt="Logo Delta"/>:</label>
							<input type="text" className="form-control" id="csdelta" onChange={(e)=>this.isiData('csDirgantara', e)}/>
						</div>
						<div className="form-group">
							<label forhtml="csorari">Callsign ORARI <img src={Logoorari} width="10%" height="10%" alt="Logo ORARI" />:</label>
							<input type="text" className="form-control" id="csorari" onChange={(e)=>this.isiData('csOrari', e)}/>
						</div>
						<div className="form-group">
							<label forhtml="csrapi">Callsign RAPI <img src={Logorapi} width="10%" height="10%" alt="Logo RAPI"/>:</label>
							<input type="text" className="form-control" id="csrapi" onChange={(e)=>this.isiData('csRapi', e)}/>
						</div>
						<div className="form-group">
							<label forhtml="ayahkd">Nama Ayah Kandung:</label>
							<input type="text" className="form-control" id="ayahkd" onChange={(e)=>this.isiData('ayahkd', e)} />
						</div>
						<div className="form-group">
							<label forhtml="ibukd">Nama Ibu Kandung:</label>
							<input type="text" className="form-control" id="ibukd" onChange={(e)=>this.isiData('ibukd', e)}/>
						</div>
						<div className="form-group">
							<label forhtml="ayahmr">Nama Ayah Mertua:</label>
							<input type="text" className="form-control" id="ayahmr" onChange={(e)=>this.isiData('ayahmr', e)}/>
						</div>
						<div className="form-group">
							<label forhtml="ibumr">Nama Ibu Mertua:</label>
							<input type="text" className="form-control" id="ibumr" onChange={(e)=>this.isiData('ibumr', e)}/>
						</div>
						<div className="form-group">
							<label forhtml="foto">Pasfoto:</label>
							<input type="file" className="form-control" id="foto" accept="image/*" onChange={(e)=>this.uploadFoto(e)}/>
						</div>
						<div className="form-group text-right">
							<button className="btn btn-outline-secondary mr-3" onClick={(e)=>this.props.gantiHalaman('Anggota')}>Batal</button>
							<button className="btn btn-primary" onClick={this.simpanData}>Simpan</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Tambahanggota);
