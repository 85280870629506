import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from 'axios';
import {connect} from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';

class Aktifnon extends Component {
	constructor(props) {
		super(props);
		this.state = {kodeuser:'', status_aktif:'', dUser:[], ada:0, vdetil:''};
	}

	isiUser = () => {
		let svr = this.props.server + "/cpengguna/res_daftarpengguna";
		Axios.get(svr).then(
			(response) => {
				if (response.data.kode==='00') {
					this.setState({dUser:response.data.isi});
				}
				else {
					alert("Terjadi Kesalahan:\n" + response.data.pesan);
				}
			}
		).catch((error)=> {
			alert("Terjadi Error: ", error);
		});
	}

	detilAktifUser = (e) => {
		let svr = this.props.server + "/cpengguna/res_detil";
		this.setState({ada:0, kodeuser:e.target.value});
		Axios.post(svr, {kode:e.target.value}, this.props.kepala).then(
			(response) => {
				if (response.data.kode==='00') {
					this.setState({vdetil:response.data.isi, ada:1});
				}
				else {
					alert("Terjadi Kesalahan:\n" + response.data.pesan);
				}
			}
		).catch((error) => {
			alert("Terjadi Error: ", error);
		})
	}

	tmpGantiStatus = (e) => {
		if (e.target.checked) {
			this.setState({status_aktif:1});
		}
		else {
			this.setState({status_aktif:0});
		}
	}

	simpanStatus = () => {
		let svr = this.props.server + "/cpengguna/res_staktif";
		var konfir = window.confirm("Apakah Anda Yakin Ingin Mengganti Status Aktif?");
		if (konfir) {
			Axios.post(svr, {kodeuser:this.state.kodeuser,
							 status_aktif:this.state.status_aktif,
							 useraktif:reactLocalStorage.get('pengguna')},
					   this.props.kepala
				   ).then(
					   (response) => {
						   if (response.data.kode==='00') {
							   alert("Status Aktif BERHASIL DIGANTI...");
							   this.props.gantiHalaman('Adm');
						   }
						   else {
							   alert("Terjadi Kesalahan:\n" + response.data.pesan);
						   }
					   }
				   ).catch((error) => {
					  alert('Terjadi Error: ', error);
				   });
		}
	}

	componentDidMount() {
		this.isiUser();
	}

	render() {
		let userDetil;
		if (this.state.ada===1) {
			userDetil =
			<div className="row justify-content-center">
				<div className="col-12">
					<div className="form-group">
						<div className="custom-control custom-switch">
						  <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={parseInt(this.state.status_aktif,10)===1 ? true : false} onChange={(e)=>this.tmpGantiStatus(e)} />
						  <label className="custom-control-label" htmlFor="customSwitch1">{this.state.vdetil.status_aktif==="1" ? "Aktif" : "Non Aktif"}</label>
						</div>
					</div>
					<div className="form-group text-right">
						<button className="btn btn-warning" onClick={this.simpanStatus}>Simpan</button>
					</div>
				</div>
			</div>
		}
		else {
			userDetil = null;
		}

		return (
			<div className="container-fluid">
				<Subjudul judul="Ganti Status Aktif" page="Adm" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="form-group">
							<select className="form-control form-control-lg" onChange={(e)=>this.detilAktifUser(e)}>
								{this.state.dUser.map((du, index) =>
									<option key={index} value={du.kode}>{du.nama_lengkap}</option>
								)}
							</select>
						</div>
					</div>
				</div>
				{userDetil}
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
        server: state.server,
        kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Aktifnon)
