import React, {Component} from "react";
import Axios from "axios";
import {connect} from "react-redux";

class Gantipassdefa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: null,
            passkonfir:null,
			iduser:null
        }
    }

    isiData = (nilai, e) => {
        this.setState(
                {[nilai] : e.target.value}
        );
    }

	componentDidMount() {
		this.setState({iduser:this.props.idpengguna});
	}

    gantiPass = () => {
        let svr = this.props.server + '/cekakses/res_ganpassdefa';
        const konfir = window.confirm("Apakah Data Sudah Benar?");
        if (konfir) {
            this.setState({iduser:this.props.idpengguna});
            if (this.state.password==null) {
                alert("Password Baru Harus DIISI..."); return;
            }
            if (this.state.password.length < 8) {
                alert("Panjang Password Baru MINIMAL 8 Karakter"); return;
            }
            if (this.state.password !== this.state.passkonfir) {
                alert("Password Baru dan Konfirmasinya TIDAK SAMA...");
                return;
            }
            Axios.post(svr, this.state, this.props.kepala).then(
                    (response) => {
                        if (response.data.kode==='00') {
                            alert("Penggantian Password SUKSES...");
                            this.props.resetStatus();
                        }
                        else {
                            alert(response.data.pesan);
                        }
                    }
            ).catch((error)=>{
                alert("Terjadi Error : " + error.response)
            });
        }
        else {
            this.props.resetStatus();
        }
    }
    render() {
        return (
            <div className="container">
                <div className="row justify-content-center mt-2 mb-3">
                    <div className="col-12 text-center">
                        <div className="alert alert-warning">Anda terindikasi menggunakan password standar</div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 text-center">
                        <h4 className="text-primary">Silahkan Ganti Password Anda</h4>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <input type="password" className="form-control form-contol-lg" placeholder="Password Baru" onChange={ (e) => this.isiData('password', e)}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <input type="password" className="form-control form-contol-lg" placeholder="Ketik ulang password baru di sini" onChange={ (e) => this.isiData('passkonfir', e)} />
                    </div>
                </div>
                <div className="row justify-content-end mt-3">
                    <div className="col-6 text-right">
                        <button className="btn btn-outline-secondary mr-2" onClick={this.props.resetStatus}>Batal</button>
                        <button className="btn btn-primary" onClick={this.gantiPass}>Simpan</button>
                    </div>
                </div>
            </div>
        );
    }
}

const petaGudangkeProp = (state) => {
    return {
        idkonek : state.konek,
        idpengguna : state.id,
        server: state.server,
        kepala: state.kepala,
        halaman: state.halaman
    }
}

const petaAksikeProp = (aksi) => {
    return {
        resetStatus: () => aksi({type:'RESET_STATUS'}),
    }
}
export default connect(petaGudangkeProp, petaAksikeProp)(Gantipassdefa);
