import React, {Component} from "react";
import {connect} from "react-redux";

class Subjudul extends Component {
    panahKlik = () => {
        this.props.gantiHalaman(this.props.page);
    }
    render() {
        return (
            <div className="row bg-primary align-items-center pt-1 pb-2">
                <div className="col-2 ml-2" onClick={this.panahKlik}>
                    <ion-icon name="chevron-back-circle" size="large" style={{color:'white'}}></ion-icon>
                </div>
                <div className="col-9 text-center text-white">
                    <h5><strong>{this.props.judul}</strong></h5>
                </div>
            </div>
        )
    }
}

const petaGudangkeProp = (state) => {
    return {
        halaman: state.halaman
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}})
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Subjudul);
