import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

class Resetpassword extends Component {
	constructor(props) {
		super(props);
		this.state = {dUser:[], kode:''};
	}

	daftarUser = () => {
		let svr = this.props.server + "/cpengguna/res_daftarpengguna";
		Axios.get(svr).then(
			(response) => {
				if (response.data.kode==='00') {
					this.setState({dUser:response.data.isi});
				}
				else {
					alert("Terjadi Kesalahan: " + response.data.pesan);
				}
			}
		).catch((error) => {
			alert("Terjadi Error: ", error);
		});
	}

	klikReset = () => {
		let svr = this.props.server + "/cpengguna/res_resetpass";
		var konfir = window.confirm("Apakah Anda Yakin Ingin Mereset Password User ini?");
		if (konfir) {
			Axios.post(svr, {kode:this.state.kode, useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
				(response) => {
					if (response.data.kode==='00') {
						alert("Password User BERHASIL DIRESET");
					}
					else {
						alert(response.data.pesan);
					}
				}
			).catch((error) => {
				alert("Terjadi Error: ", error);
			})
		}
	}

	isiUser = (e) => {
		this.setState({'kode':e.target.value});
	}

	componentDidMount() {
		this.daftarUser();
	}

	render() {
		return(
			<div className="container-fluid">
				<Subjudul judul="Reset Password" page="Adm" />
				<div className="row ustify-content-center mt-4">
					<div className="col-12">
						<select className="form-control form-control-lg" onChange={(e) => this.isiUser(e)}>
							{this.state.dUser.map((du, index) =>
								<option key={index} value={du.kode}>{du.nama_lengkap}</option>
							)}
						</select>
					</div>
				</div>
				<div className="row ustify-content-center mt-3">
					<div className="col-12">
						<button className="btn btn-block btn-lg btn-warning" onClick={this.klikReset}>Reset Password</button>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
        halaman: state.halaman,
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Resetpassword);
