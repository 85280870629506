import React from 'react';
//import logo from './logo.svg';
//import './App.css';
import Utama from './satkomapps/utama';

function App() {
  return (
      <div>
        <Utama />
      </div>
  );
}



export default App;
