import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

class Tambahpengguna extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kode: null,
            nama_lengkap: null
        }
    }
    isiData = (kolom, e) => {
        this.setState(
            {[kolom]:e.target.value}
        );
    }

    resetData = () => {
        this.setState({kode:null, nama_lengkap:null});
        document.getElementById('ftambahpengguna').reset();
    }

    kirimData = () => {
        let svr = this.props.server + '/cpengguna/tambah_pengguna';
        var konfir = window.confirm("Apakah Data Sudah Benar?");
        if (konfir) {
            Axios.post(svr, {kode:this.state.kode, nama_lengkap:this.state.nama_lengkap, useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
                (response) => {
                    if (response.data.kode==='00') {
                        alert("Data Pengguna Berhasil DITAMBAH");
                        this.resetData();
                    }
                    else {
                        alert("Terjadi Kesalahan: " + response.data.pesan);
                    }
                }
            ).catch((error)=>{
                //console.log(error);
                alert('Tambah Pengguna Baru: ' + error);
            });
        }
    }
    render() {
        return (
            <div className="container">
                <Subjudul judul="Tambah Pengguna Baru" page="Adm" />
                <form id="ftambahpengguna">
                <div className="row mt-5">
                    <div className="col-12">
                        <input type="text" maxLength="10" className="form-control" placeholder="Kode Login" onChange={(e) => this.isiData('kode', e)} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <input type="text" maxLength="40" className="form-control" placeholder="Nama Lengkap" onChange={(e) => this.isiData('nama_lengkap', e)}/>
                    </div>
                </div>
                </form>
                <div className="row justify-content-end mt-2">
                    <div className="col-8 text-right">
                        <button className="btn btn-lg btn-secondary mr-1" onClick={(e) => this.props.gantiHalaman('Adm')}>Batal</button>
                        <button className="btn btn-lg btn-primary" onClick={this.kirimData}>Simpan</button>
                    </div>
                </div>
            </div>
        )
    }
}

const petaGudangkeProp = (state) => {
    return {
        uA : state.id,
        halaman: state.halaman,
        server: state.server,
        kepala: state.kepala,
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Tambahpengguna);
