import React, {Component} from "react";
import Judulutama from './judulutama';
import Kartumenu from './kartumenu';
import {connect} from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';
import Axios from 'axios';

class Menuutama extends Component {
	constructor(props) {
		super(props);
		this.state = {dAkses:[],
					  anggota:0,
					  iuran:0,
					  callbook:0,
					  administrator:0
					 };
	}

    bacaNama = () => {
        let tgl = new Date();
        return reactLocalStorage.get('pengguna') + " " + tgl.getDate() + "-" + (tgl.getMonth()+1) + "-" + tgl.getFullYear();
    }


	getAkses = () => {
		let svr = this.props.server + "/akses/res_cekakseskat";
		Axios.post(svr, {iduser:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
			 (response) => {
				if (response.data.kode==='00') {
					 this.setState({dAkses:response.data.isi}, ()=>{
						this.state.dAkses.map((dkat, index) => {
							switch (dkat.kode_kategori) {
								case 'KF001' :
												if (dkat.status==="1") {
													this.setState({anggota:1});
												}
												else {
													this.setState({anggota:0});
												}
												break;
								case 'KF002' :
												if (dkat.status==="1") {
													this.setState({iuran:1});
												}
												else {
													this.setState({iuran:0});
												}
												break;
								case 'KF003' :
												if (dkat.status==="1") {
													this.setState({callbook:1});
												}
												else {
													this.setState({callbook:0});
												}
												break;
								case 'KF004' :
												if (dkat.status==="1") {
													this.setState({administrator:1});
												}
												else {
													this.setState({administrator:0});
												}
												break;
								default: this.setState({anggota:0,
		  					  							iuran:0,
		  					  							callbook:0,
		  					  							administrator:0});
							}

							return this.state;
						});
					});

				}
				else {
					alert('Terjadi Kesalahan: ' + response.data.pesan);
				}
			}
		).catch((error) => {
			alert('Terjadi Error: ', error);
		});
	}

	componentDidMount() {
		this.getAkses();
	}

    render() {
        const nama = this.bacaNama();
		let vmanggota;
		let vmiuran;
		let vmcallbook;
		let vmadmin;

		if (this.state.anggota===1) {
			vmanggota = <div className="col-6">
							<Kartumenu judul="Anggota" gambar="member.png" page="Anggota" />
						</div>;
		}
		else {
			vmanggota = null;
		}
		if (this.state.iuran===1) {
			vmiuran =	<div className="col-6">
							<Kartumenu judul="Iuran" gambar="dana.png" page="Iuran" />
						</div>
		}
		else {
			vmiuran = null;
		}
		if (this.state.callbook===1) {
			vmcallbook = <div className="col-6">
							<Kartumenu judul="Callbook" gambar="callbook.png" page="Callbook" />
						</div>;
		}
		else {
			vmcallbook = null;
		}
		if (this.state.administrator===1) {
			vmadmin = <div className="col-6">
					  	<Kartumenu judul="Administrator" gambar="administrator.png" page="Adm" />;
					  </div>
		}
		else {
			vmadmin = null;
		}
        return (
             <div className="container">
                <Judulutama />
				<div className="row mt-5 justify-content-center">
					{vmanggota}
					{vmiuran}
				</div>
				<div className="row mt-5 justify-content-center">
					{vmcallbook}
					{vmadmin}
				</div>
                <div className="row mt-5 bg-danger text-white">
                    <div className="col-12 text-center">
                        <p>{nama}</p>
                    </div>
                </div>
             </div>
        );
    }
}

const petaGudangkeProp = (state) => {
    return {
		server:state.server,
		kepala:state.kepala
    }
}

export default connect(petaGudangkeProp)(Menuutama);
