import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

class Adm extends Component {
	constructor(props) {
		super(props);
		this.state = {dAkses:[],
					  adduser:0,
					  edituser:0,
					  deluser:0,
					  activeuser:0,
					  listuser:0,
					  accessuser:0,
					  resetpass:0,
					  gantipass:0
				  };
	}

	getAkses = () => {
		let svr = this.props.server + "/akses/res_cekaksftruser";
		Axios.post(svr, {useraktif:reactLocalStorage.get('pengguna'), kodekategori:'KF004'}, this.props.kepala).then(
			(response) => {
				if (response.data.kode==='00')  {
					this.setState({dAkses:response.data.isi}, ()=>{
						this.state.dAkses.map((dft, index) => {
							switch (dft.kode_fitur) {
								case 'FT00001' :
									dft.status==="1" ? this.setState({adduser:1}) : this.setState({adduser:0});
									break;
								case 'FT00002':
									dft.status==="1" ? this.setState({edituser:1}) : this.setState({edituser:0});
									break;
								case 'FT00003':
										dft.status==="1" ? this.setState({deluser:1}) : this.setState({deluser:0});
										break;
								case 'FT00004':
										dft.status==="1" ? this.setState({activeuser:1}) : this.setState({activeuser:0});
										break;
								case 'FT00005':
										dft.status==="1" ? this.setState({listuser:1}) : this.setState({listuser:0});
										break;
								case 'FT00006':
										dft.status==="1" ? this.setState({accessuser:1}) : this.setState({accessuser:0});
										break;
								case 'FT00007':
										dft.status==="1" ? this.setState({resetpass:1}) : this.setState({resetpass:0});
										break;
								case 'FT00008':
										dft.status==="1" ? this.setState({gantipass:1}) : this.setState({gantipass:0});
										break;
								default: this.setState({dAkses:[],
											  			adduser:0,
											  			edituser:0,
											  			deluser:0,
											  			activeuser:0,
											  			listuser:0,
											  			accessuser:0,
														resetpass:0
										  				});
							}
							return this.state;
						});
					});
				}
			}
		).catch((error) => {
			alert("Terjadi Error: ", error);
		});
	}

	componentDidMount() {
		this.getAkses();
	}
    render() {
		let vtambahuser;
		let vedituser;
		let vhapususer;
		let vaktifuser;
		let vdftuser;
		let vaksesuser;
		let vresetpassword;
		let vgantipass;

		vtambahuser = this.state.adduser===1 ?
					  <div className="row justify-content-center" style={{marginTop:"15%"}}>
		  	  				<div className="col-12">
			  					<button className="btn btn-lg btn-block btn-primary" onClick={(e) =>this.props.gantiHalaman('TambahPengguna')}>Tambah Pengguna Baru</button>
		  					</div>
	  				  </div> : null;
		vedituser = this.state.edituser===1 ?
					<div className="row justify-content-center mt-3">
						<div className="col-12">
							<button className="btn btn-lg btn-block btn-warning" onClick={(e)=>this.props.gantiHalaman('KoreksiPengguna')}>Koreksi Pengguna</button>
						</div>
					</div> : null;
		vhapususer = this.state.deluser===1 ?
					<div className="row justify-content-center mt-3">
						<div className="col-12">
							<button className="btn btn-lg btn-block btn-danger" onClick={(e)=>this.props.gantiHalaman('HapusPengguna')}>Hapus Pengguna</button>
						</div>
					</div> : null;
		vaktifuser = this.state.activeuser===1 ?
					<div className="row justify-content-center mt-3">
						<div className="col-12">
							<button className="btn btn-lg btn-block btn-outline-secondary" onClick={(e)=>this.props.gantiHalaman('StatusAktif')}>Aktif - Non Aktif Pengguna</button>
						</div>
					</div> : null;
		vdftuser = this.state.listuser===1 ?
					<div className="row mt-3">
					 	<div className="col-12">
						 	<button className="btn btn-lg btn-block btn-info" onClick={(e)=>this.props.gantiHalaman('DaftarPengguna')}>Daftar Seluruh Pengguna</button>
					 	</div>
				 	</div> : null;
		vaksesuser = this.state.accessuser===1 ?
					<div className="row mt-3">
						<div className="col-12">
							<button className="btn btn-lg btn-block btn-danger" onClick={(e)=>this.props.gantiHalaman('HakAkses')}>Pengaturan Hak Akses</button>
						</div>
					</div> : null;
		vresetpassword = this.state.accessuser===1 ?
					<div className="row mt-3">
						<div className="col-12">
							<button className="btn btn-lg btn-block btn-success" onClick={(e)=>this.props.gantiHalaman('ResetPassword')}>Reset Password</button>
						</div>
					</div> : null;
		vgantipass = this.state.accessuser===1 ?
					<div className="row mt-3">
						<div className="col-12">
							<button className="btn btn-lg btn-block btn-outline-warning" onClick={(e)=>this.props.gantiHalaman('GantiPassword')}>Ganti Password</button>
						</div>
					</div> : null;
        return (
            <div className="container">
                <Subjudul judul="Administrator" />
				{vtambahuser}
				{vedituser}
				{vhapususer}
				{vaktifuser}
				{vdftuser}
				{vaksesuser}
				{vresetpassword}
				{vgantipass}
            </div>
        )
    }
}

const petaGudangkeProp = (state) => {
    return {
        halaman: state.halaman,
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Adm);
