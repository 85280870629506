import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";

class Jenisiuran extends Component {

	render() {
		return (
			<div className="container-fluid">
				<Subjudul judul="Jenis-Jenis Iuran" page="Iuran" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<button className="btn btn-lg btn-block btn-primary" onClick={(e)=>this.props.gantiHalaman('TambahIuran')}>Tambah Jenis Iuran</button>
					</div>
				</div>
				<div className="row justify-content-center mt-3">
					<div className="col-12">
						<button className="btn btn-lg btn-block btn-warning" onClick={(e)=>this.props.gantiHalaman('KoreksiIuran')}>Koreksi Jenis Iuran</button>
					</div>
				</div>
				<div className="row justify-content-center mt-3">
					<div className="col-12">
						<button className="btn btn-lg btn-block btn-danger" onClick={(e)=>this.props.gantiHalaman('HapusIuran')}>Hapus Jenis Iuran</button>
					</div>
				</div>
				<div className="row justify-content-center mt-3">
					<div className="col-12">
						<button className="btn btn-lg btn-block btn-info" onClick={(e)=>this.props.gantiHalaman('InfoIuran')}>Informasi Jenis Iuran</button>
					</div>
				</div>
			</div>

		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		halaman:state.halaman
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Jenisiuran);
