import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

class Iuran extends Component {
	constructor(props) {
		super(props);
		this.state = {dAkses:[],
					  jenisIuran:0,
					  gratisIuran:0,
					  tglAwalbayar:0,
					  bayarIuran:0,
					  kartuIuran:0,
					  infoBayar:0
				  };
	}

	getAkses = () => {
		let svr = this.props.server + "/akses/res_cekaksftruser";
		Axios.post(svr, {useraktif:reactLocalStorage.get('pengguna'), kodekategori:'KF002'}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dAkses:res.data.isi}, ()=>{
						this.state.dAkses.forEach(dft=>{
							switch(dft.kode_fitur) {
								case 'FT00014' :
									dft.status==="1" ? this.setState({jenisIuran:1}) : this.setState({jenisIuran:0});
									break;
								case 'FT00015' :
									dft.status==="1" ? this.setState({gratisIuran:1}) : this.setState({gratisIuran:0});
									break;
								case 'FT00016' :
									dft.status==="1" ? this.setState({bayarIuran:1}) : this.setState({bayarIuran:0});
									break;
								case 'FT00017' :
									dft.status==="1" ? this.setState({kartuIuran:1}) : this.setState({kartuIuran:0});
									break;
								case 'FT00018' :
									dft.status==="1" ? this.setState({infoBayar:1}) : this.setState({infoBayar:0});
									break;
								case 'FT00019' :
									dft.status==="1" ? this.setState({tglAwalbayar:1}) : this.setState({tglAwalbayar:0});
									break;
								default:
								this.setState({dAkses:[],
											  jenisIuran:0,
											  tglAwalbayar:0,
											  gratisIuran:0,
											  bayarIuran:0,
											  kartuIuran:0,
											  infoBayar:0
										  	});
							}
						})
					});
				}
			}
		).catch((error)=> {
			alert("Terjadi Error:\n" + error);
		});
	}

	componentDidMount() {
		this.getAkses();
	}

	render() {
		let vjenisiuran;
		let vgratisiuran;
		let vbayariuran;
		let vkartuiuran;
		let vinfobayar;
		let vtglawalbayar;

		vjenisiuran = this.state.jenisIuran===1 ?
					  <div className="row justify-content-center mt-5">
					  	<div className="col-12">
							<button className="btn btn-lg btn-block btn-primary" onClick={(e)=>this.props.gantiHalaman('JenisIuran')}>Jenis Iuran</button>
						</div>
					  </div> : null;
		vtglawalbayar = this.state.tglAwalbayar===1 ?
					  <div className="row justify-content-center mt-3">
					  	<div className="col-12">
							<button className="btn btn-lg btn-block btn-success" onClick={(e)=>this.props.gantiHalaman('TglAwalBayar')}>Setting Tgl. Awal Bayar</button>
						</div>
					  </div> : null;
		vgratisiuran = this.state.gratisIuran===1 ?
						<div className="row justify-content-center mt-3">
							<div className="col-12">
								<button className="btn btn-lg btn-block btn-danger" onClick={(e)=>this.props.gantiHalaman('AnggotaGratis')}>Anggota Gratis Iuran</button>
							</div>
					    </div> : null;
		vbayariuran = this.state.bayarIuran===1 ?
						<div className="row justify-content-center mt-3">
							<div className="col-12">
								<button className="btn btn-lg btn-block btn-success" onClick={(e)=>this.props.gantiHalaman('BayarIuran')}>Pembayaran Iuran</button>
							</div>
						</div> : null;
		vkartuiuran = this.state.kartuIuran===1 ?
						<div className="row justify-content-center mt-3">
							<div className="col-12">
								<button className="btn btn-lg btn-block btn-warning">Kartu Iuran</button>
							</div>
						</div> : null;
		vinfobayar = this.state.infoBayar===1 ?
						<div className="row justify-content-center mt-3">
							<div className="col-12">
								<button className="btn btn-lg btn-block btn-info">Informasi Pembayaran</button>
							</div>
						</div> : null;
		return(
			<div className="container-fluid">
				<Subjudul judul="Iuran" page="Menuutama" />
				{vjenisiuran}
				{vtglawalbayar}
				{vgratisiuran}
				{vbayariuran}
				{vkartuiuran}
				{vinfobayar}
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Iuran);
