import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

class Anggota extends Component {
	constructor(props) {
		super(props);
		this.state = {dAkses:[],
					  tbhaggota:0,
					  krkanggota:0,
					  hpsanggota:0,
					  agtkeluarga:0,
					  dftanggota:0
					 };
	}

	getAkses = () => {
		let svr = this.props.server + "/akses/res_cekaksftruser";
		Axios.post(svr, {useraktif:reactLocalStorage.get('pengguna'), kodekategori:'KF001'}, this.props.kepala).then(
			(response) => {
				if (response.data.kode==='00') {
					this.setState({dAkses:response.data.isi}, ()=>{
						this.state.dAkses.forEach(dft => {
							switch(dft.kode_fitur) {
								case 'FT00009' :
									dft.status==="1" ? this.setState({tbhanggota:1}) : this.setState({tbhanggota:0});
									break;
								case 'FT00010' :
									dft.status==="1" ? this.setState({krkanggota:1}) : this.setState({krkanggota:0});
									break;
								case 'FT00011' :
									dft.status==="1" ? this.setState({hpsanggota:1}) : this.setState({hpsanggota:0});
									break;
								case 'FT00012' :
									dft.status==="1" ? this.setState({agtkeluarga:1}) : this.setState({agtkeluarga:0});
									break;
								case 'FT00013' :
									dft.status==="1" ? this.setState({dftanggota:1}) : this.setState({dftanggota:0});
									break;

								default: this.setState({dAkses:[],
														tbhaggota:0,
								  						krkanggota:0,
								  						hpsanggota:0,
								  						agtkeluarga:0,
								  						dftanggota:0
										  				});
							}
						});
					});
				}
			}
		).catch((error) => {
			alert("Terjadi Error: ", error);
		});
	}

	componentDidMount() {
		this.getAkses();
	}

	render() {
		let vtbhanggota;
		let vkoranggota;
		let vhpsanggota;
		let vagtkeluarga;
		let vdftanggota;

		vtbhanggota = this.state.tbhanggota===1 ?
					  <div className="row justify-content-center mt-5">
					  	<div className="col-11">
							<button className="btn btn-lg btn-block btn-success" onClick={(e)=>this.props.gantiHalaman('TambahAnggota')}>Tambah Anggota Baru</button>
						</div>
					  </div> : null;
	    vkoranggota = this.state.krkanggota===1 ?
					 <div className="row justify-content-center mt-3">
					 	<div className="col-11">
							<button className="btn btn-lg btn-block btn-warning" onClick={(e)=>this.props.gantiHalaman('KoreksiAnggota')}>Koreksi Anggota</button>
						</div>
					 </div> : null;
	 	vhpsanggota = this.state.hpsanggota===1 ?
					 <div className="row justify-content-center mt-3">
					 	<div className="col-11">
							<button className="btn btn-lg btn-block btn-danger" onClick={(e)=>this.props.gantiHalaman('HapusAnggota')}>Hapus Anggota</button>
						</div>
					 </div> : null;
		vagtkeluarga = this.state.agtkeluarga===1 ?
 					 <div className="row justify-content-center mt-3">
 					 	<div className="col-11">
 							<button className="btn btn-lg btn-block btn-secondary" onClick={(e)=>this.props.gantiHalaman('Keluarga')}>Anggota Keluarga</button>
 						</div>
 					 </div> : null;
		vdftanggota = this.state.dftanggota===1 ?
 					 <div className="row justify-content-center mt-3">
 					 	<div className="col-11">
 							<button className="btn btn-lg btn-block btn-info" onClick={(e)=>this.props.gantiHalaman('DaftarAnggota')}>Lihat Daftar Anggota</button>
 						</div>
 					 </div> : null;
		return(
			<div className="container-fluid">
				<Subjudul judul="Anggota" page="Menuutama" />
				{vtbhanggota}
				{vkoranggota}
				{vhpsanggota}
				{vagtkeluarga}
				{vdftanggota}
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Anggota);
