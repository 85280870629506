import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from "axios";
import {connect} from "react-redux";

class Koreksianggota extends Component {
	constructor(props) {
		super(props);
		this.state = {cari:'', dAngg:[]};
	}

	isiCari = (e) => {
		this.setState({cari:e.target.value, ada:0});
	}

	doCari = () => {
		let svr = this.props.server + "/canggota/res_carianggota";
		this.setState({ada:0});
		Axios.post(svr, {cari:this.state.cari}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dAngg: res.data.isi, ada:1});
				}
				else {
					alert("Terjadi Kesalahan:\n" + res.data.pesan);
				}
			}
		).catch((error)=> {
			alert("Terjadi Error: \n" + error);
		})
	}

	isiFormulir = (noAngg) => {
		this.props.isinomoranggota(noAngg);
		this.props.gantiHalaman('FrmKorAnggota');
	}

	render () {
		let vdaftar;

		if (this.state.ada===1) {
			vdaftar = <div className="row justify-content-center mt-5">
							<div className="col-12">
								{this.state.dAngg.map((da, index)=>
									<table className="table table-sm mb-2" key={index}>
										<tbody>
											<tr>
												<td className="table-info">No. Anggota:</td>
												<td className="bg-warning" style={{fontSize:"14pt"}}>{da.nomor_anggota}</td>
											</tr>
											<tr>
												<td className="table-info">Callsign:</td>
												<td className="bg-warning" style={{fontSize:"18pt"}}>{da.callsign_delta}</td>
											</tr>
											<tr>
												<td className="table-info">Callsign:</td>
												<td className="bg-warning"><strong>{da.nama_lengkap}</strong></td>
											</tr>
											<tr>
												<td colSpan="2" className="text-right">
													<button className="btn btn-lg btn-outline-warning" onClick={(e)=>this.isiFormulir(da.nomor_anggota)}>Koreksi</button>
												</td>
											</tr>
										</tbody>
									</table>
								)}
							</div>
					  </div>
		}
		else {
			vdaftar = null;
		}
		return (
			<div className="container-fluid">
				<Subjudul judul="Koreksi Data Anggota" page="Anggota" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="input-group">
							<input type="text" className="form-control" placeholder="No. Anggota / Callsign / Nama Anggota" onChange={(e)=>this.isiCari(e)}/>
							<div className="input-group-append">
								<button className="btn btn-primary" onClick={this.doCari}>Cari</button>
							</div>
						</div>
					</div>
				</div>
				{vdaftar}
			</div>

		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
		isinomoranggota: (noangg) => aksi({type:'ISI_NO_ANGGOTA', payload:{nomoranggota:noangg}}),
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}})
    }
}
export default connect(petaGudangkeProp, petaAksikeProp)(Koreksianggota);
