import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from "axios";
import {connect} from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';

class Aksesfitur extends Component {
	constructor(props) {
		super(props);
		this.state = {dUser:[],
					  dAkses:[],
					  kodeuser:''
		             };
	}

	isiDaftarUser = () => {
		let svr = this.props.server + '/cpengguna/res_daftarpengguna';
		Axios.get(svr, this.props.kepala).then(
			(response) => {
				if (response.data.kode==='00') {
					const dUser = response.data.isi;
					this.setState({dUser});
				}
				else {
					alert("Terjadi Kesalahan Daftar Pengguna: " + response.data.pesan);
				}
			}
		).catch((error)=>{
			alert("Daftar Pengguna Error: " + error);
		});
	}

	isiUser = (e) => {
		let svr = this.props.server + '/akses/res_listfiturkatuser';
		this.setState({kodeuser:e.target.value});
		Axios.post(svr, {kodeuser:e.target.value, useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
			(response) => {
				if (response.data.kode === '00') {
					this.setState({dAkses:response.data.isi})
				}
				else {
					alert("Terjadi Kesalahan: \n\n" + response.data.pesan);
				}
			}
		).catch((error) => {
			alert("Terjadi Error: ", error);
		})
	}

	cekCekBox = (e) => {
		let svr = this.props.server + '/akses/res_updatetmpfitur';
		Axios.post(svr, {
					kodeuser:this.state.kodeuser,
					useraktif:reactLocalStorage.get('pengguna'),
					kodefitur:e.target.value,
					status:e.target.checked
				}, this.props.kepala).then(
					(response) => {
						if (response.data.kode==='00') {
							this.setState({dAkses:response.data.isi});
						}
						else {
							alert(response.data.pesan);
						}
					}
				).catch((error) => {
					alert("Terjadi Error: ", error);
				});
	}

	simpanFitur = () => {
		let svr = this.props.server + '/akses/res_smpaksesfitur';
		var konfir = window.confirm("Apakah Data Sudah Benar?");
		if (konfir) {
			Axios.post(svr, {kodeuser:this.state.kodeuser, useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
				(response) => {
					if (response.data.kode==='00') {
						this.setState({dAkses:[]});
						alert("Setting Akses Fitur BERHASIL DISIMPAN");
					}
					else {
						alert(response.data.pesan);
					}
				}
			).catch((error)=> {
				alert("Terjadi Error: ", error);
			})
		}
	}

	componentDidMount() {
		this.isiDaftarUser();
    }

	render() {
		return(
			<div className="container fluid">
				<Subjudul judul="Izin Akses Fitur" page="HakAkses" />
				<div className="row justify-content-center mt-5">
					<div className="col-12 text-center">
						<select className="form-control form-control-lg" onChange={(e) => this.isiUser(e)} >
							{this.state.dUser.map (
								(duser, index) => <option key={index} value={duser.kode}>{duser.nama_lengkap}</option>
							)}
						</select>
					</div>
				</div>
				{this.state.dAkses.filter(akses => akses.status_kat==="1").map(
					(akses, index) =>
							<div className="row justify-content-center mt-5" key={index}>
								<div className="col-12">
									<div className="card">
										<div className="card-header">
											<h5>{akses.deskripsi}</h5>
										</div>
										<div className="card-body">
											<table className="table">
												<tbody>
												{akses.fitur !== null ?
													(akses.fitur.map(
														(xfitur, findex) =>
														<tr key={findex}>
															<td>{xfitur.des_fitur}</td>
															<td className="custom-control custom-switch">
																<input type="checkbox" className="custom-control-input" id={xfitur.kode_fitur} value={xfitur.kode_fitur} onChange={(e) => this.cekCekBox(e)} checked={parseInt(xfitur.status_fitur,10)===1 ? true : false} />
																<label className="custom-control-label" htmlFor={xfitur.kode_fitur}>&nbsp;</label>
															</td>
														</tr>
													)) : null
												}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
					)
				}

				<div className="row justify-content-end mt-3 mb-2">
					<div className="col-5 text-right">
						<button className="btn btn-primary btn-block" onClick={this.simpanFitur}>Simpan</button>
					</div>
				</div>
			</div>

		);
	}
}

const petaGudangkeProp = (state) => {
    return {
        idpengguna : state.id,
        server: state.server,
        kepala: state.kepala,
		kodeuser: state.kodeuser
    }
}

export default connect(petaGudangkeProp)(Aksesfitur);
