import React, {Component} from "react";
import Logo from '../gbr/logo.jpeg';
import {connect} from "react-redux";
import Axios from "axios";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idpengguna : null,
            password: null
        };
    }

    isiData = (nilai, e) => {
        this.setState(
            {[nilai]:e.target.value}
        );
    }

    aksiLogin = () => {
        let svr = this.props.server + '/cekakses/periksa_login';
        //console.log("state: ", this.state);
        Axios.post(svr, this.state, this.props.kepala).then(
            (response) => {
                if (response.data.kode==='05') {
                    this.props.gantiPassDefa(this.state.idpengguna);
                    this.props.gantiHalaman('GantiPassDefa');
                }
                else if (response.data.kode==='00') {
                    this.props.loginSukses(response.data.isi);
                    this.props.gantiHalaman('Menuutama');
                }
                else {
                    alert("Error: " + response.data.pesan);
                }
            }
        ).catch((error)=>{
            //console.log(error);
            alert('Periksa Login Error: ' + error);
        });
    }

    tombolEnter = (event) => {
        if (event.key==='Enter') {
            this.aksiLogin();
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-4">
                        <img src={Logo} width="100%" alt=""/>
                    </div>
                </div>
                <div className="row" style={{marginBottom:"10%"}}>
                    <div className="col-12 text-center text-danger">
                        <h2><strong>Satkom Dirgantara</strong></h2>
                    </div>
                </div>
				<div className="row justify-content-center mt-2">
					<div className="d-none d-sm-none d-md-none d-lg-block">
						<div className="alert alert-danger">
							<h1>Hanya Bisa Dijalankan di Smartphone (HP)/Tablet</h1>
						</div>
					</div>
				</div>
				<div className="d-block d-sm-block d-md-block d-lg-none">
	                <div className="row mb-3">
	                    <div className="col-12">
	                        <input className="form-control form-contol-lg" type="text" placeholder="Login" onChange={ (e) => this.isiData('idpengguna', e)}/>
	                    </div>
	                </div>
	                <div className="row mb-2">
	                    <div className="col-12">
	                        <input className="form-control form-contol-lg" type="password" placeholder="Password" onChange={ (e) => this.isiData('password', e)} onKeyPress={this.tombolEnter}/>
	                    </div>
	                </div>
	                <div className="row justify-content-end mr-1">
	                    <div className="col-3">
	                        <button className="btn btn-primary" onClick={this.aksiLogin}>Login</button>
	                    </div>
	                </div>
				</div>
                <div className="row bg-danger" style={{marginTop:"80%", paddingTop:"3%", paddingBottom:"3%"}}>
                    <div className="col-12 text-center text-light">
                        <small>Pengurus Satkom Dirgantara &copy; 2020 - versi 1.0</small>
                    </div>
                </div>
            </div>
        )
    }
}

const petaGudangkeProp = (state) => {
    return {
        idkonek : state.konek,
        idpengguna : state.id,
        server: state.server,
        kepala: state.kepala,
        halaman: state.halaman
    }
}

const petaAksikeProp = (aksi) => {
    return {
        bacaStatus: () => aksi({type:'BACA_STATUS'}),
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
        gantiPassDefa: (iduser) => aksi({type:'GANTI_PASSDEFA', payload:{iduser:iduser}}),
        loginSukses: (idkonek) => aksi({type:'LOGIN_SUKSES', payload:{datalogin:idkonek}})
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Login);
