import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from "axios";
import {connect} from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';

class Akseskategori extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dKat : [],
			dUser : [],
			kodeuser: '',
			namaCek:'',
			nilaiCheck: false
		}
	}

	isiKategori = () => {
		let svr = this.props.server + '/akses/res_daftarkategori';
		Axios.get(svr, this.props.kepala).then(
			(response) => {
				if (response.data.kode==='00') {
					const dKat = response.data.isi;
					this.setState({dKat});
				}
				else {
					alert('Terjadi Kesalahan Daftar Kategori : ' + response.data.pesan);
				}
			}
		).catch((error)=> {
			alert('Daftar Kategori Error: ' + error);
		});
	}

	isiDaftarUser = () => {
		let svr = this.props.server + '/cpengguna/res_daftarpengguna';
		Axios.get(svr, this.props.kepala).then(
			(response) => {
				if (response.data.kode==='00') {
					const dUser = response.data.isi;
					this.setState({dUser});
				}
				else {
					alert("Terjadi Kesalahan Daftar Pengguna: " + response.data.pesan);
				}
			}
		).catch((error)=>{
			alert("Daftar Pengguna Error: " + error);
		});
	}

	isiDaftarKategori = (e) => {
		let svr = this.props.server + '/akses/res_dkatuser';
		this.setState({kodeuser:e.target.value});
		Axios.post(svr, {kode:e.target.value, useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
			(response) => {
				if (response.data.kode==='00') {
					this.setState({dKat:response.data.isi});
				}
				else {
					alert("Terjadi Kesalahan: " + response.data.pesan);
				}
			}
		).catch((error) => {
			alert("Terjadi Error: " + error);
		});
	}

	cekNilaiBox = (e) => {
		let svr = this.props.server + '/akses/res_updatetmpkat';
		Axios.post(svr,
				   {kodeuser:this.state.kodeuser,
					useraktif:reactLocalStorage.get('pengguna'),
					kodekategori:e.target.value,
					status:e.target.checked
				   },
				   this.props.kepala
			   ).then(
				   (response) => {
					   this.setState({dKat:response.data.isi});
				   }
			   ).catch((error) => {
				  alert("Terjadi Error: " + error);
			  });
	}

	simpan = () => {
		let svr = this.props.server + '/akses/res_simpankategori';
		var konfir = window.confirm("Apakah Data Sudah Benar?");
		if (konfir) {
			Axios.post(svr,
					   {iduser:this.state.kodeuser, useraktif:reactLocalStorage.get('pengguna')},
					   this.props.kepala
				   ).then(
					   (response) => {
						   if (response.data.kode==='00') {
							   alert("Izin Akses Menu Utama BERHASIL DISIMPAN");
							   this.setState({dKat:[]});
						   }
						   else {
							   alert(response.data.pesan);
						   }
					   }
				   ).catch((error) => {
					   alert("Terjadi Error: " + error);
				   })
		}

	}

	componentDidMount() {
		this.isiDaftarUser();
    }

	render() {
			return (
				<div className="container fluid">
					<Subjudul judul="Izin Akses Menu Utama" page="HakAkses" />
					<div className="row justify-content-center" style={{marginTop:"10%"}}>
						<div className="col-10">
							<select className="form-control form-control-lg" onChange={(e) => this.isiDaftarKategori(e)} value={this.state.kode_user}>
								{this.state.dUser.map(
									(du, index) => <option key={index} value={du.kode}>{du.nama_lengkap}</option>
								)}
							</select>
						</div>
					</div>
					<div className="row justify-content-center mt-5">
						<div className="col-10">
							<table className="table table-sm table-hover">
								<tbody>
									{this.state.dKat.map(
										(dk, index) =>
										<tr key={index}>
											<td>{dk.deskripsi}</td>
											<td className="custom-control custom-switch">
												<input type="checkbox" className="custom-control-input" id={dk.kode_kategori} value={dk.kode_kategori} onChange={(e)=>this.cekNilaiBox(e)} checked={parseInt(dk.status,10)===1 ? true : false}/>
												<label className="custom-control-label" htmlFor={dk.kode_kategori}>&nbsp;</label>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
					<div className="row justify-content-end mt-3">
						<div className="col-5">
							<button className="btn btn-primary btn-block" onClick={this.simpan}>Simpan</button>
						</div>
					</div>
				</div>
			)
		/*}*/
	}

}

const petaGudangkeProp = (state) => {
    return {
        idpengguna : state.id,
        server: state.server,
        kepala: state.kepala,
        halaman: state.halaman
    }
}


export default connect(petaGudangkeProp)(Akseskategori);
