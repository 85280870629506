import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";

class Bayariuran extends Component {
	render() {
		return (
			<div className="container-fluid">
				<Subjudul judul="Pembayaran Iuran" page="Iuran" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<button className="btn btn-block btn-lg btn-warning" onClick={(e)=>this.props.gantiHalaman('BuatTagihan')}>Buat Tagihan</button>
					</div>
				</div>
				<div className="row justify-content-center mt-2">
					<div className="col-12">
						<button className="btn btn-block btn-lg btn-primary" onClick={(e)=>this.props.gantiHalaman('BayarTagihan')}>Bayar Tagihan</button>
					</div>
				</div>
				<div className="row justify-content-center mt-2">
					<div className="col-12">
						<button className="btn btn-block btn-lg btn-secondary">Koreksi Tagihan</button>
					</div>
				</div>
				<div className="row justify-content-center mt-2">
					<div className="col-12">
						<button className="btn btn-block btn-lg btn-danger">Koreksi Pembayaran</button>
					</div>
				</div>
			</div>
		);
	}
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(null, petaAksikeProp)(Bayariuran);
