import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from "axios";
import {connect} from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';

class Anggratis extends Component {
	constructor(props) {
		super(props);
		this.state = {dgratis:[],
					  cari:'',
					  diuran:[],
					  kodeIuran:'',
					  sLoading:0,
					  tglAkhir:'',
					 }
	}

	daftarIuran = () => {
		let svr = this.props.server + "/ciuran/res_daftariuran";
		this.setState({sLoading:1});
		Axios.post(svr, {cari:null}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({diuran:res.data.isi, sLoading:0});
				}
				else {
					this.setState({sLoading:0});
					alert("Belum Ada Iuran Yang Terdaftar\nPeriksa Kembali Data Iuran");
					this.props.gantiHalaman('Iuran');
				}
			}
		).catch((error) => {
			this.setState({sLoading:0});
			alert("Terjadi Kesalahan Daftar Iuran:\n" + error);
			this.props.gantiHalaman('Iuran');
		});
	}

	pilihIuran = (e) => {
		this.setState({kodeIuran:e.target.value});
	}

	isiCari = (e) => {
		this.setState({cari:e.target.value});
	}

	isiTglAkhir = (e) => {
		this.setState({tglAkhir:e.target.value});
	}

	simpanGratis = (lnoAng) => {
		let svr = this.props.server + "/ciuran/res_tambahgratis";
		let konfir = window.confirm("Apakah Anda Yakin Ingin Menggratiskan Anggota Ini?");
		if (konfir) {
			this.setState({sLoading:1});
			Axios.post(svr, {nomor_anggota:lnoAng,
							 kodeiuran:this.state.kodeIuran,
							 tgl_akhir:this.state.tglAkhir,
							 useraktif:reactLocalStorage.get('pengguna')
					  }, this.props.kepala).then(
						  (res) => {
							  if (res.data.kode==='00') {
								  this.setState({sLoading:0});
								  alert("Anggota Berhasil DIGRATISKAN IURANNYA");
								  this.props.gantiHalaman('Iuran');
							  }
							  else {
								  this.setState({sLoading:0});
								  alert('Terjadi Kesalahan:\n ' + res.data.pesan);
							  }
						  }
					  ).catch((error) => {
						  this.setState({sLoading:0});
						  alert("Terjadi Error:\n" + error);
					  });
		}
	}

	batalGratis = (lnoAng) => {
		let svr = this.props.server + "/ciuran/res_batalgratis";
		var konfir = window.confirm("Apakah Anda Yakin Ingin Menghapus Gratis Iuran dari Anggota Ini?");
		if (konfir) {
			this.setState({sLoading:1});
			Axios.post(svr, {nomor_anggota:lnoAng,
							 kodeiuran:this.state.kodeIuran,
							 useraktif:reactLocalStorage.get('pengguna')
						 }, this.props.kepala).then(
							 (res) => {
								 if (res.data.kode==='00') {
									 alert("Gratis Iuran BERHASIL DIBATALKAN");
									 this.props.gantiHalaman('Iuran');
								 }
								 else {
									 alert("Terjadi Kesalahan:\n" + res.data.pesan);
								 }
							 }
						 ).catch((error) => {
							 this.setState({sLoading:0});
							 alert("Terjadi Error:\n" + error);
						 });
		}
	}

	daftarGratis = (lkodeiuran, lcari) => {
		let svr = this.props.server + "/ciuran/daftar_gratis";
		this.setState({sLoading:1});
		Axios.post(svr, {kodeiuran:this.state.kodeIuran, cari:this.state.cari}, this.props.kepala).then(
			(res) => {
				this.setState({sLoading:0});
				if (res.data.kode==='00') {
					this.setState({sLoading:0, dgratis:res.data.isi});
				}
				else {
					this.setState({sLoading:0, dgratis:[]});
				}
			}
		).catch((error)=> {
			this.setState({sLoading:0});
			alert("Terjadi Error:\n" + error);
			this.props.gantiHalaman('Iuran');
		});
	}



	componentDidMount() {
		this.daftarIuran();
	}

	componentWillUmount() {
		clearTimeout();
	}

	render() {
		let vdaftar;
		//let vbayar;

		vdaftar = this.state.dgratis.length > 0 ?
				  <div className="row justify-content-center mt-5">
				  	<div className="col-12">
						{this.state.dgratis.map((dg, index) =>
							<table key={index} className="table table-sm">
								<tbody>
									<tr>
										<td className="table-info">No. Anggota</td>
										<td className={dg.status_gratis===0 ? "bg-info" : "bg-danger text-light"} style={{fontSize:"14pt"}}>{dg.nomor_anggota}</td>
									</tr>
									<tr>
										<td className="table-info">Nama Lengkap:</td>
										<td className={dg.status_gratis===0 ? "bg-info" : "bg-danger text-light"}>{dg.nama_lengkap}</td>
									</tr>
									<tr>
										<td className="table-info">Callsign Delta:</td>
										<td className={dg.status_gratis===0 ? "bg-info" : "bg-danger text-light"}><strong>{dg.callsign_delta}</strong></td>
									</tr>
									<tr>
										<td className="table-info">Status Gratis Iuran:</td>
										<td className={dg.status_gratis===0 ? "bg-info" : "bg-danger text-light"}><strong>{dg.status_gratis_d}</strong></td>
									</tr>
									<tr className={dg.status_gratis===0 ? "visible" : "d-none"}>
										<td className="table-danger">Tgl.Batas Gratis</td>
										<td className="bg-danger"><input type="date" className="form-control"  onChange={(e)=>this.isiTglAkhir(e)} /></td>
									</tr>
									<tr className={dg.status_gratis===0 ? "visible" : "d-none"}>
										<td className="table-danger text-right" colSpan="2">
											<button className="btn btn-danger" onClick={(e)=>this.simpanGratis(dg.nomor_anggota)}>Set Gratis Iuran</button>
										</td>
									</tr>
									<tr className={dg.status_gratis===1 ? "visible" : "d-none"}>
										<td className="table-primary">Tgl. Batas Gratis</td>
										<td className="bg-danger text-white"><strong>{dg.tgl_akhir_gratis}</strong></td>
									</tr>
									<tr className={dg.status_gratis===1 ? "visible" : "d-none"}>
										<td className="table-primary text-right" colSpan="2">
											<button className="btn btn-primary" onClick={(e)=>this.batalGratis(dg.nomor_anggota)}>Batalkan Gratis Iuran</button>
										</td>
									</tr>
								</tbody>
							</table>
						)}
					</div>
				  </div> : null;

		return (
			<div className="container-fluid">
				<Subjudul judul="Anggota Gratis Iuran" page="Iuran" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text">Iuran</span>
							</div>
							<select className="form-control" value={this.state.kodeIuran} onChange={(e)=>this.pilihIuran(e)}>
								<option value={null}>&nbsp;</option>
								{this.state.diuran.map((di, index) =>
									<option key={index} value={di.kode}>{di.deskripsi}</option>
								)}
							</select>
						</div>
					</div>
				</div>
				<div className="row justify-content-center mt-2">
					<div className="col-12">
						<div className="input-group">
							<input type="text" className="form-control" placeholder="No. Anggota/Callsign Delta/Nama" onChange={(e)=>this.isiCari(e)} />
							<div className="input-group-append">
								<button className="btn btn-danger" onClick={(e)=>this.daftarGratis(this.state.kodeiuran)}>Cari</button>
							</div>
						</div>
					</div>
				</div>
				{this.state.sLoading===1 ?
				 <div className="row justify-content-center mt-3">
				 	<div className="col-5 text-center">
						<div className="spinner-border text-primary">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				 </div> : null
			    }
				{vdaftar}
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
	return {
		server: state.server,
		kepala: state.kepala,
	}
}

const petaAksikeProp = (aksi) => {
	return {
		gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
	}
}

export default connect(petaGudangkeProp, petaAksikeProp)(Anggratis);
