import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from "axios";
import {connect} from "react-redux";

class Daftaruser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dUser: []
        }
    }

    isiData = () => {
        let svr = this.props.server + '/cpengguna/res_daftarpengguna';
        Axios.get(svr).then(
            (response) => {
                if (response.data.kode==='00') {
                    const dUser = response.data.isi;
                    this.setState({dUser});
                }
				else {
					alert("Terjadi Kesalahan Daftar Pengguna : " + response.data.pesan);
				}
            }
        ).catch((error) => {
            alert("Daftar Pengguna Error : " + error);
        });
    }

	detilUser = (kode) => {
		this.props.isiKodeUser(kode);
		this.props.gantiHalaman('DetilPengguna');
	}

    componentDidMount() {
        this.isiData();
    }

    render() {
        return(
            <div className="container-fluid">
                <Subjudul judul="Daftar Pengguna" page="Adm" />
                <div className="row mt-2">
                    <div className="col-12">
                        <ul className="list-group list-group-flush">
                            {this.state.dUser.map(
                                (d, index) => <li key={d.kode} className="list-group-item">
                                    Kode Login:<h4 className="text-success"><strong>{index+1}.&nbsp;{d.kode}</strong></h4>
                                    Nama Lengkap:&nbsp;<strong>{d.nama_lengkap}</strong>
									<p className="text-right">
										<button className="btn btn-info" onClick={(e)=>this.detilUser(d.kode)}>Detil</button>
									</p>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

const petaGudangkeProp = (state) => {
    return {
        idkonek : state.konek,
        idpengguna : state.id,
        server: state.server,
        kepala: state.kepala,
        halaman: state.halaman
    }
}

const petaAksikeProp = (aksi) => {
	return {
		isiKodeUser : (kodeuser) => aksi({type:'ISI_KODEUSER', payload:{kodeuser:kodeuser}}),
		gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
	}
}

export default connect(petaGudangkeProp, petaAksikeProp)(Daftaruser);
