import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

class Buattagihan extends Component {
	constructor(props) {
		super(props);
		this.state = {dBln:[],
					  dThn:[],
					  sLoading:0,
					  blnP:'',
					  thnP:''
					 };
	}

	isiPeriode = (isian, e) => {
		this.setState({[isian]:e.target.value});
	}

	daftarBulan = () => {
		let svr = this.props.server + "/ciuran/res_daftarbulan";
		this.setState({sLoading:1});
		Axios.get(svr).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({sLoading:0,
								   dBln:res.data.isi
								  });
				}
				else {
					this.setState({sLoading:1});
					alert("Terjadi Kesalahan:\n" + res.data.pesan);
					this.props.gantiHalaman('BayarIuran');
				}
			}
		).catch((error)=> {
			this.setState({sLoading:0});
			alert("Terjadi Error:\n", error);
			this.props.gantiHalaman('BayarIuran');
		});
	}

	daftarTahun = () => {
		let svr = this.props.server + "/ciuran/res_daftarTahun";
		this.setState({sLoading:1});
		Axios.get(svr).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({sLoading:0,
								   dThn:res.data.isi
								  });
				}
				else {
					this.setState({sLoading:1});
					alert("Terjadi Kesalahan:\n" + res.data.pesan);
					this.props.gantiHalaman('BayarIuran');
				}
			}
		).catch((error)=> {
			this.setState({sLoading:0});
			alert("Terjadi Error:\n", error);
			this.props.gantiHalaman('BayarIuran');
		});
	}

	buatTagihan = () => {
		let svr = this.props.server + "/ciuran/res_buattagihan";
		var konfir = window.confirm("Apakah Anda Yakin Ingin Membuat Tagihan");
		if (konfir) {
			this.setState({sLoading:1});
			Axios.post(svr, {bulan:this.state.blnP,
							 tahun:this.state.thnP,
							 useraktif: reactLocalStorage.get('pengguna')
						 }, this.props.kepala).then(
							 (res)=>{
								 this.setState({sLoading:0});
								 if (res.data.kode==='00') {
									 alert(res.data.pesan);
								 }
								 else {
									 alert("Terjadi Kesalahan:\n" + res.data.pesan);
								 }
							 }).catch((error)=>{
								this.setState({sLoading:0});
								alert("Terjadi Error:\n", error);
							 });
		}
	}

	componentDidMount() {
		this.daftarBulan();
		this.daftarTahun();
	}

	render() {
		return (
			<div className="container-fluid">
				<Subjudul judul="Buat Tagihan" page="BayarIuran" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="form-row">
							<div className="form-group col-6">
								<label>Periode Tagihan</label>
								<select className="form-control" onChange={(e)=>this.isiPeriode('blnP', e)}>
									<option value="">&nbsp;</option>
									{this.state.dBln.map((dftBln, index) =>
										<option key={index} value={dftBln.blnangka}>{dftBln.blnnama}</option>
									)}
								</select>
							</div>
							<div className="form-group col-6">
								<label>&nbsp;</label>
								<select className="form-control" onChange={(e)=>this.isiPeriode('thnP', e)}>
									<option value="">&nbsp;</option>
									{this.state.dThn.map((dftThn, index) =>
										<option key={index} value={dftThn.tahun}>{dftThn.tahun}</option>
									)}
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="row justify-content-center mt-2">
					<div className="col-12">
						<button className="btn btn-lg btn-block btn-warning" onClick={this.buatTagihan}>Buat Tagihan</button>
					</div>

				</div>
				{this.state.sLoading===1 ?
				 <div className="row justify-content-center mt-3">
				 	<div className="col-5 text-center">
						<div className="spinner-border text-primary">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				 </div> : null
			    }
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Buattagihan);
