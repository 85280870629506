import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from 'axios';
import {connect} from "react-redux";

class Callbook extends Component {
	constructor(props) {
		super(props);
		this.state = {dAng:[],
					  sLoad:0
					 };
	}

	daftarAnggota = () => {
		let svr = this.props.server + "/canggota/res_daftaranggota";
		this.setState({sLoad:1});
		Axios.post(svr, {cari:null}, this.props.kepala).then(
			(res)=>{
				if (res.data.kode==='00') {
					this.setState({dAng:res.data.isi, sLoad:0});
				}
				else {
					this.setState({dAng:[], sLoad:0});
					alert("Terjadi Kesalahan:\n" + res.data.pesan);
				}
			}
		).catch((error)=>{
			this.setState({sLoad:0});
			alert("Terjadi Error:\n" + error);
		})
	}

	isiCari = (e) => {
		this.setState({cari:e.target.value});
	}

	cariAnggota = () =>  {
		let svr = this.props.server + "/canggota/res_daftaranggota";
		Axios.post(svr, {cari:this.state.cari}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dAng:res.data.isi});
				}
				else {
					this.setState({dAng:[]});
				}
			}
		).catch((error) => {
			alert("Terjadi Error:\n" + error);
		});
	}

	detilCallbook = (noangg) => {
		this.props.isinomoranggota(noangg);
		this.props.gantiHalaman('DetilCB');
	}

	componentDidMount() {
		this.daftarAnggota();
	}

	render() {
		let vdaftar;

		vdaftar = this.state.dAng.length > 0 ?
					<div className="row justify-content-center mt-3">
						<div className="col-12">
							<ul className="list-group list-group-flush">
								{this.state.dAng.map((vang, index) =>
									<li className="list-group-item" key={index}>
										<table className="table table-sm">
											<tbody>
												<tr>
													<td className="bg-info">No. Anggota:</td>
													<td className="bg-info" style={{fontSize:"14pt"}}>{vang.nomor_anggota}</td>
												</tr>
												<tr>
													<td className="bg-warning">Call Sign Delta:</td>
													<td className="bg-warning" style={{fontSize:"18pt"}}>{vang.callsign_delta}</td>
												</tr>
												<tr>
													<td className="table-info">Nama Lengkap:</td>
													<td className="table-info" style={{fontSize:"14pt"}}>{vang.nama_lengkap}</td>
												</tr>
												<tr className="bg-light">
													<td colSpan="2" className="text-right">
														<button className="btn btn-primary" onClick={(e)=>this.detilCallbook(vang.nomor_anggota)}>Detil</button>
													</td>
												</tr>
											</tbody>
										</table>
									</li>
								)}
							</ul>
						</div>
					</div> : null;
		return(
			<div className="container-fluid">
				<Subjudul judul="Callbook Anggota" page="Menuutama" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="input-group">
							<input type="text" className="form-control" placeholder="No. Anggota/Nama/Callsign" onChange={(e)=>this.isiCari(e)} />
							<div className="input-group-append">
								<button className="btn btn-warning" onClick={this.cariAnggota}>Cari</button>
							</div>
						</div>
					</div>
				</div>
				{this.state.sLoad===1 ?
				 <div className="row justify-content-center mt-3">
				 	<div className="col-5 text-center">
						<div className="spinner-border text-primary">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				 </div> : null
			    }
				{vdaftar}
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
	return {
		isinomoranggota: (noangg) => aksi({type:'ISI_NO_ANGGOTA', payload:{nomoranggota:noangg}}),
		gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}})
	}
}

export default connect(petaGudangkeProp, petaAksikeProp)(Callbook);
