import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from "axios";
import {connect} from "react-redux";

class Detiluser extends Component {
	constructor(props) {
		super(props);
		this.state = {detilUser:''};
	}

	dataDetil = () => {
		let svr = this.props.server + "/cpengguna/res_detil";
		Axios.post(svr, {kode:this.props.kodeuser}, this.props.kepala).then(
			(response) => {
				if (response.data.kode==='00') {
					this.setState({detilUser:response.data.isi});
				}
				else {
					alert("Terjadi Kesalahan:\n" + response.data.pesan);
				}
			}
		).catch((error) => {
			alert("Terjadi Error: ", error);
		});
	}

	doSelesai = () => {
		this.props.gantiHalaman('DaftarPengguna');
	}

	componentDidMount() {
		this.dataDetil();
	}

	render() {
		return(
			<div className="container-fluid">
				<Subjudul judul="Detil User" page="DaftarPengguna" />
				<div className="row justify-content-center mt-5">
					<div className="col-11">
						<ul className="list-group list-group-flush">
							<li className="list-group-item">
								<div className="display-4">{this.state.detilUser.kode}</div>
							</li>
							<li className="list-group-item">
								Nama:&nbsp;<strong>{this.state.detilUser.nama_lengkap}</strong>
							</li>
							<li className="list-group-item">
								Nama:&nbsp;<strong>{this.state.detilUser.status_aktif==="1" ? "Aktif" : "Tidak Aktif"}</strong>
							</li>
							<li className="list-group-item">
								Password Standar:&nbsp;<strong>{this.state.detilUser.passdefa}</strong>
							</li>
							<li className="list-group-item">
								Waktu Entry:&nbsp;<strong>{this.state.detilUser.waktu_input}</strong>
							</li>
							<li className="list-group-item">
								Petugas Entry:&nbsp;<strong>{this.state.detilUser.user_input}</strong>
							</li>
							<li className="list-group-item">
								Waktu Koreksi:&nbsp;<strong>{this.state.detilUser.waktu_edit}</strong>
							</li>
							<li className="list-group-item">
								Petugas Koreksi:&nbsp;<strong>{this.state.detilUser.user_edit}</strong>
							</li>
						</ul>
					</div>
				</div>
				<div className="row justify-content-end mt-3">
					<div className="col-12 text-right">
						<button className="btn btn-outline-primary" onClick={this.doSelesai}>Selesai</button>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
        server: state.server,
        kepala: state.kepala,
		kodeuser: state.kodeuser
    }
}

const petaAksikeProp = (aksi) => {
    return {
		isiKodeUser : (kodeuser) => aksi({type:'ISI_KODEUSER', payload:{kodeuser:kodeuser}}),
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Detiluser);
