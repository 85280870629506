import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from "axios";
import {connect} from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';

class Hapusanggota extends Component {
	constructor(props) {
		super(props);
		this.state = {cari:'', dAng:[]};
	}

	isiCari = (e) => {
		this.setState({cari:e.target.value});
	}

	doCari = () => {
		let svr = this.props.server + "/canggota/res_carianggota";
		Axios.post(svr,{cari:this.state.cari}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dAng:res.data.isi});
				}
				else {
					alert("Data Tidak Ditemukan");
					this.setState({dAng:[]});
				}
			}
		).catch((error)=>{
			alert("Terjadi Error:\n" + error);
		})
	}

	doHapus = (noAngg, cs, nama) => {
		let svr = this.props.server + '/canggota/res_hapusanggota';
		var konfir = window.confirm("Apakah Anda Yakin Ingin Menghapus Anggota dengan Call Sign: " + cs + " atas nama " + nama + "?");
		if (konfir) {
			Axios.post(svr, {nomor_anggota:noAngg, useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
				(res) => {
					if (res.data.kode==='00') {
						alert("Anggota dengan Callsign " + cs  + " atas nama " + nama + " BERHASIL DIHAPUS");
						this.props.gantiHalaman('Anggota');
					}
					else {
						alert("Terjadi Kesalahan:\n" + res.data.pesan);
					}
				}
			).catch((error) => {
				alert("Terjadi Error: \n" + error);
			});
		}
	}

	render() {
		let vdaftar;
		vdaftar = this.state.dAng.length <= 0 ? null :
				  <div className="row justify-content-center mt-3">
				  	{this.state.dAng.map((da, index) =>
						<table className="table table-sm mb-2" key={index}>
							<tbody>
								<tr>
									<td className="table-danger">No. Anggota:</td>
									<td className="text-danger" style={{fontSize:"14pt"}}>{da.nomor_anggota}</td>
								</tr>
								<tr>
									<td className="table-danger">Call Sign:</td>
									<td className="text-danger" style={{fontSize:"14pt"}}><strong>{da.callsign_delta}</strong></td>
								</tr>
								<tr>
									<td className="table-danger">Nama Lengkap:</td>
									<td className="text-danger"><strong>{da.nama_lengkap}</strong></td>
								</tr>
								<tr>
									<td colSpan="2" className="text-right">
										<button className="btn btn-danger" onClick={(e)=>this.doHapus(da.nomor_anggota, da.callsign_delta, da.nama_lengkap)}>Hapus</button>
									</td>
								</tr>
							</tbody>
						</table>
					)}
				  </div>

		return (
			<div className="container-fluid">
				<Subjudul judul="Penghapusan Anggota" page="Anggota" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="input-group">
							<input type="text" className="form-control" onChange={(e)=>this.isiCari(e)} placeholder="No. Anggota/Callsign/Nama Lengkap" />
							<div className="input-group-append">
								<button className="btn btn-danger" onClick={this.doCari}>Cari</button>
							</div>
						</div>
					</div>
				</div>
				{vdaftar}
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}})
    }
}
export default connect(petaGudangkeProp, petaAksikeProp)(Hapusanggota);
