import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from 'axios';
import {connect} from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';

class Frmkoreksi extends Component {
	constructor(props) {
		super(props);
		this.state = {nama:''}
	}

	isiForm = () => {
		let svr = this.props.server + "/cpengguna/res_detil";

		Axios.post(svr, {kode:this.props.kodeuser}, this.props.kepala).then(
			(response) => {
				if (response.data.kode==='00') {
					this.setState({nama:response.data.isi.nama_lengkap});
				}
				else {
					alert("Terjadi Kesalahan: " + response.data.pesan);
					this.props.gantiHalaman('KoreksiPengguna');
				}
			}
		).catch((error) => {
			alert("Terjadi Error: ", error);
		});
	}

	doBatal = () => {
		this.props.gantiHalaman('KoreksiPengguna');
	}

	isiData = (kolom, e) => {
		this.setState({[kolom] : e.target.value});
	}

	simpanKoreksi = () => {
		let svr = this.props.server + "/cpengguna/res_spnuser";
		var konfir = window.confirm("Apakah Data Sudah Benar?");
		if (konfir) {
			Axios.post(svr, {kode:this.props.kodeuser, nama:this.state.nama, useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
				(response) => {
					if (response.data.kode==='00') {
						alert("Data BERHASIL DISIMPAN...");
						this.props.gantiHalaman('KoreksiPengguna');
					}
					else {
						alert("Terjadi Kesalahan: \n" + response.data.pesan);
					}
				}
			).catch((error) => {
				alert("Terjadi Error: ", error);
			})
		}
	}

	componentDidMount() {
		this.isiForm();
	}

	render() {
		return(
			<div className="container-fluid">
				<Subjudul judul="Koreksi Data Pengguna" page="KoreksiPengguna" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="form-group">
							<label htmlFor="kode">Kode Login:</label>
							<input type="text" id="kode" className="form-control" disabled value={this.props.kodeuser}/>
						</div>
						<div className="form-group">
							<label htmlFor="nama">Nama Lengkap:</label>
							<input type="text" id="nama" className="form-control" value={this.state.nama} onChange={(e) => this.isiData('nama', e)}/>
						</div>
						<div className="form-group text-right">
							<button className="btn btn-outline-secondary mr-3" onClick={this.doBatal}>Batal</button>
							<button className="btn btn-primary" onClick={this.simpanKoreksi}>Simpan</button>
						</div>
					</div>
				</div>
			</div>
		)
	}

}

const petaGudangkeProp = (state) => {
    return {
        server: state.server,
        kepala: state.kepala,
		kodeuser: state.kodeuser
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Frmkoreksi);
