import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from 'axios';
import {connect} from "react-redux";

class Bayartagihan extends Component {
	constructor(props) {
		super(props);
		this.state = {dAng:[],
					  cari:'',
					  sLoad:0,
					  blnPeriode:0,
					  thnPeriode:0,
					  dBln:[],
					  dThn:[]
					 }
	}

	isiCari = (e) => {
		this.setState({cari:e.target.value});
	}

	isiBln = (e) => {
		this.setState({blnPeriode: e.target.value});
	}

	isiThn = (e) => {
		this.setState({thnPeriode: e.target.value});
	}

	daftarBulan = () => {
		let svr = this.props.server + "/ciuran/res_daftarbulan";
		this.setState({sLoad:1});
		Axios.get(svr).then((res)=> {
			if (res.data.kode==='00') {
				this.setState({sLoad:0, dBln: res.data.isi})
			}
			else {
				this.setState({sLoad:0});
				alert("Terjadi Kesalahan:\n" + res.data.pesan);
			}
		}).catch((error)=>{
			this.setState({sLoad:0});
			alert("Terjadi Error : \n" + error);
		})
	}

	daftarTahun = () => {
		let svr = this.props.server + "/ciuran/res_daftartahun";
		this.setState({sLoad:1});
		Axios.get(svr).then((res)=> {
			if (res.data.kode==='00') {
				this.setState({sLoad:0, dThn: res.data.isi})
			}
			else {
				this.setState({sLoad:0});
				alert("Terjadi Kesalahan:\n" + res.data.pesan);
			}
		}).catch((error)=>{
			this.setState({sLoad:0});
			alert("Terjadi Error : \n" + error);
		})
	}

	daftarAnggota = () => {
		let svr = this.props.server + "/canggota/res_carianggota";
		this.setState({sLoad:1});
		Axios.post(svr, {cari:this.state.cari}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dAng:res.data.isi, sLoad:0});
				}
				else {
					this.setState({dAng:[], sLoad:0});
					alert("Terjadi Kesalahan:\n" + res.data.pesan);
				}
			}
		).catch((error) => {
			this.setState({sLoad:0});
			alert("Terjadi Error:\n" + error);
		})
	}

	catatPembayaran = (nomorAnggota) => {
		this.props.isiNomorAnggota(nomorAnggota);
		//this.props.isiPeriode(this.state.blnPeriode, this.state.thnPeriode);
		this.props.gantiHalaman('CatatBayar');
	}

	render() {
		let vdaftar;

		vdaftar = this.state.dAng.length > 0 ?
				  <div className="row justify-content-center mt-3">
				  	  <div className="col-12">
					  	 {this.state.dAng.map((vang, index) =>
							 <table className="table table-sm" key={index}>
							 	<tbody>
									<tr>
										<td className="table-info">No. Anggota</td>
										<td style={{fontSize:"14pt"}} className="bg-info text-light">{vang.nomor_anggota}</td>
									</tr>
									<tr>
										<td className="table-info">Nama:</td>
										<td className="bg-info text-light"><strong>{vang.nama_lengkap}</strong></td>
									</tr>
									<tr>
										<td className="table-info">Callsign:</td>
										<td style={{fontSize:"14pt"}} className="bg-info text-light"><strong>{vang.callsign_delta}</strong></td>
									</tr>
									<tr>
										<td colSpan="2" className="text-right">
											<button className="btn btn-success" onClick={(e)=>this.catatPembayaran(vang.nomor_anggota)}>Catat Pembayaran</button>
										</td>
									</tr>
								</tbody>
							 </table>
						 )}
					  </div>
				  </div> : null;
		return(
			<div className="container-fluid">
				<Subjudul judul="Bayar Tagihan" page="BayarIuran" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="input-group">
							<input type="text" className="form-control" placeholder="No. Anggota/Nama/Callsign" onChange={(e)=>this.isiCari(e)} />
							<div className="input-group-append">
								<button className="btn btn-primary" onClick={this.daftarAnggota}>Cari</button>
							</div>
						</div>
					</div>
				</div>
				{this.state.sLoad===1 ?
				 <div className="row justify-content-center mt-3">
				 	<div className="col-5 text-center">
						<div className="spinner-border text-primary">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				 </div> : null
			    }
				{vdaftar}
			</div>
		)
	}

}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala,
		bulanPeriode: state.bulanPeriode
    }
}

const petaAksikeProp = (aksi) => {
    return {
		isiNomorAnggota: (noAng) => aksi({type:'ISI_NO_ANGGOTA', payload:{nomoranggota:noAng}}),
		//isiPeriode: (blnP, thnP) => aksi({type:'ISI_PERIODE_TAGIHAN', payload:{bulanPeriode:blnP, tahunPeriode:thnP}}),
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Bayartagihan);
