import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from 'axios';
import {connect} from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';

class Frmhapususer extends Component {
	constructor(props) {
		super(props);
		this.state = {nama:'',
					  status_aktif:'',
					  waktu_input:'',
					  user_input:'',
					  waktu_edit:'',
					  user_edit:''
					 };
	}

	isiForm = () => {
		let svr = this.props.server + "/cpengguna/res_detil";

		Axios.post(svr, {kode:this.props.kodeuser}, this.props.kepala).then(
			(response) => {
				if (response.data.kode==='00') {
					this.setState({nama:response.data.isi.nama_lengkap,
								   status_aktif:response.data.isi.status_aktif==="1" ? "Aktif" : "Non Aktif",
								   waktu_input:response.data.isi.waktu_input,
								   user_input:response.data.isi.user_input,
								   waktu_edit:response.data.isi.waktu_edit===null ? '' : response.data.isi.waktu_edit,
								   user_edit:response.data.isi.user_edit===null ? '' : response.data.isi.user_edit
								  });
				}
				else {
					alert("Terjadi Kesalahan: " + response.data.pesan);
					this.props.gantiHalaman('HapusPengguna');
				}
			}
		).catch((error) => {
			alert("Terjadi Error: ", error);
		});
	}

	doBatal = () => {
		this.props.gantiHalaman('HapusPengguna');
	}

	hapusUser = () => {
		let svr = this.props.server + "/cpengguna/res_hpsuser";
		var konfir = window.confirm("Apakah ANDA YAKIN INGIN MENGHAPUS PENGGUNA INI?");
		if (konfir) {
			Axios.post(svr, {kode:this.props.kodeuser, useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
				(response) => {
					if (response.data.kode==='00') {
						alert("Data Pengguna BERHASIL DIHAPUS");
						this.props.gantiHalaman('HapusPengguna');
					}
					else {
						alert("Terjadi KESALAHAN: \n" + response.data.pesan);
					}
				}
			).catch((error) => {
				alert("Terjadi Error: ", error);
			})
		}
	}

	componentDidMount() {
		this.isiForm();
	}

	render() {
		return (
			<div className="container-fluid">
				<Subjudul judul="Hapus Data Pengguna" page="HapusPengguna" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="form-group">
							<label htmlFor="kode">Kode Login:</label>
							<input type="text" id="kode" className="form-control" disabled value={this.props.kodeuser}/>
						</div>
						<div className="form-group">
							<label htmlFor="nama">Nama Lengkap:</label>
							<input type="text" id="nama" className="form-control" value={this.state.nama} disabled />
						</div>
						<div className="form-group">
							<label htmlFor="staktif">Status Aktif:</label>
							<input type="text" id="staktif" className="form-control" value={this.state.status_aktif} disabled />
						</div>
						<div className="form-group">
							<label htmlFor="wktinput">Waktu Entry:</label>
							<input type="text" id="wktinput" className="form-control" value={this.state.waktu_input} disabled />
						</div>
						<div className="form-group">
							<label htmlFor="userinput">Petugas Entry:</label>
							<input type="text" id="userinput" className="form-control" value={this.state.user_input} disabled />
						</div>
						<div className="form-group">
							<label htmlFor="wktedit">Waktu Koreksi:</label>
							<input type="text" id="wktedit" className="form-control" value={this.state.waktu_edit} disabled />
						</div>
						<div className="form-group">
							<label htmlFor="useredit">Petugas Koreksi:</label>
							<input type="text" id="useredit" className="form-control" value={this.state.user_edit} disabled />
						</div>
						<div className="form-group text-right">
							<button className="btn btn-outline-secondary mr-3" onClick={this.doBatal}>Batal</button>
							<button className="btn btn-danger" onClick={this.hapusUser}>Hapus</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
        server: state.server,
        kepala: state.kepala,
		kodeuser: state.kodeuser
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Frmhapususer);
