import React, {Component} from "react";
import {connect} from "react-redux";
import Login from './login';


class Judulutama extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ganti:false
		}
	}
    aksiLogout = () => {
        let konfir = window.confirm("Apakah Anda Yakin Ingin Logout?");
        if (konfir) {
			this.setState({ganti:true});
            this.props.logOut();
			window.location.reload(true);
            return (<Login />);
        }
    }
    render() {
        return (
            <div className="row bg-primary align-items-center pt-2 pb-2">
                <div className="col-8 ml-2 pl-2">
                    <h3>Menu Utama</h3>
                </div>
                <div className="col-2 mr-4">
                    <button className="btn btn-outline-secondary" onClick={this.aksiLogout}>Logout</button>
                </div>
            </div>

        )
    }
}

const petaGudangkeProp = (state) => {
    return {
        idkonek : state.konek,
        idpengguna : state.id
    }
}

const petaAksikeProp = (aksi) => {
    return {
        logOut: () => aksi({type:'LOGOUT_SUKSES'})
    }
}
export default connect(petaGudangkeProp, petaAksikeProp)(Judulutama);
