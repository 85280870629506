import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from 'axios';
import {connect} from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';

class Frmkeluarga extends Component {
	constructor(props) {
		super(props);
		this.state = {dkel:[],
					  dAngg:[],
					  dStatus:[],
					  nomorBaris:'',
					  nomorAnggota:'',
					  kodeKeluarga:'',
					  namaLengkap:'',
					  statusKel:'',
					  jenisKelamin:'',
					  barisTmp:0
				     };
	}

	ambilDataAnggota = () => {
		let svr = this.props.server + "/canggota/res_detilanggota";
		Axios.post(svr, {nomor_anggota:this.props.nomoranggota}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dAngg:res.data.isi,
								   nomorBaris:res.data.isi.nomor_baris,
								   nomorAnggota:res.data.isi.nomor_anggota
							      });
				}
				else {
					alert("Terjadi Kesalahan: \n" + res.data.pesan);
					this.props.gantiHalaman('Keluarga');
				}
			}
		).catch((error) => {
			alert("Terjadi Error Data Anggota: \n" + error);
			this.props.gantiHalaman('Keluarga');
		})
	}

	ambilStatus = () => {
		let svr = this.props.server + "/ckeluarga/res_daftarstatus";
		Axios.get(svr).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dStatus:res.data.isi});
				}
				else {
					alert("Terjadi Kesalahan:\n" + res.data.pesan);
				}
			}
		).catch((error)=>{
			alert("Terjadi Error Data Status Keluarga:\n" + error);
			this.props.gantiHalaman('Keluarga');
		})
	}

	ambilKeluarga = () => {
		let svr = this.props.server + "/ckeluarga/res_daftarkeluarga";
		Axios.post(svr, {nomor_anggota:this.props.nomoranggota, useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dkel:res.data.isi});
				}
				else {
					this.setState({dkel:[]});
				}
			}
		).catch((error) => {
			alert("Terjadi Error Data Keluarga: \n" + error);
		});
	}

	isiForm = (k, e) => {
		this.setState({[k]:e.target.value});
	}

	barisPilih = (xdata) => {
		this.setState({nomorBaris:xdata.nomor_baris_anggota,
					   nomorAnggota:xdata.nomor_anggota,
					   kodeKeluarga:xdata.kode_keluarga===null ? '' : xdata.kode_keluarga,
					   namaLengkap:xdata.nama_lengkap,
					   statusKel:xdata.kode_status,
					   barisTmp:xdata.baris,
					   jenisKelamin:xdata.jenis_kelamin});
	}

	tbhKor = () => {
		let svr = this.props.server + "/ckeluarga/res_tbhkortemp";
		Axios.post(svr, {nomor_anggota:this.state.nomorAnggota,
					     nomor_baris_anggota:this.state.nomorBaris,
						 kode_status:this.state.statusKel,
						 nama_lengkap:this.state.namaLengkap,
						 jenis_kelamin:this.state.jenisKelamin,
						 useraktif:reactLocalStorage.get('pengguna'),
						 kode_keluarga:this.state.kodeKeluarga,
						 baris_tmp:this.state.barisTmp
					 }, this.props.kepala
				 ).then(
					 (res) => {
						 if (res.data.kode==='00') {
							 this.setState({dkel:res.data.isi,
								 			kodeKeluarga:'',
											namaLengkap:'',
											jenisKelamin:'',
											statusKel:'',
											barisTmp:0
							 			   });
						 }
						 else {
							 this.setState({dKel:[]});
							 alert("Terjadi Kesalahan:\n" + res.data.pesan);
						 }
					 }
				 ).catch((error)=>{
					alert("Terjadi Error: \n" + error);
				 });
	}

	hapusKeluarga = () => {
		let svr = this.props.server + "/ckeluarga/hapus_tmpkel";
		Axios.post(svr, {nomor_anggota:this.state.nomorAnggota,
					     nomor_baris_anggota:this.state.nomorBaris,
						 useraktif:reactLocalStorage.get('pengguna'),
						 kode_keluarga:this.state.kodeKeluarga,
						 baris_tmp:this.state.barisTmp},
					this.props.kepala
				).then(
					(res) => {
						this.setState({dKel:[]});
						if (res.data.kode==='00') {
							this.setState({dkel:res.data.isi,
										   kodeKeluarga:'',
										   namaLengkap:'',
										   jenisKelamin:'',
										   statusKel:'',
										   barisTmp:0
										  });
						}
						else {
							this.setState({dkel:[]});
						}
					}
				).catch((error)=>{
					alert("Terjadi Error:\n" + error);
				});

	}

	simpanKeluarga = () => {
		let svr = this.props.server + "/ckeluarga/res_simpankeluarga";
		var konfir = window.confirm("Apakah Data Sudah Benar?");
		if (konfir) {
			Axios.post(svr, {useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
				(res) => {
					if (res.data.kode==='00') {
						alert("Data Berhasil Disimpan");
						this.props.gantiHalaman('Keluarga');
					}
					else {
						alert("Terjadi Kesalahan:\n" + res.data.pesan);
					}
				}
			).catch((error)=>{
				alert("Terjadi Error:\n" + error);
			});
		}
	}

	componentDidMount() {
		this.ambilDataAnggota();
		this.ambilKeluarga();
		this.ambilStatus();
	}

	render() {
		let vdafkel;
		if (this.state.dkel.length > 0) {
			vdafkel = <div className="row justify-content-center">
						 <div className="col-12">
						 	<h5><strong>Daftar Keluarga</strong></h5>
						 	<table className="table table-sm table-striped">
								<thead>
									<tr>
										<th>Nama</th>
										<th>Jns.Kel</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{this.state.dkel.map((dk, index) =>
										<tr key={index} onClick={(e)=>this.barisPilih(dk)}>
											<td>{dk.nama_lengkap}</td>
											<td>{dk.jenis_kelamin_d}</td>
											<td>{dk.kode_status_d}</td>
										</tr>
									)}
									<tr>
										<td colSpan="3" className="text-right">
											<button className="btn btn-success" onClick={this.simpanKeluarga}>Simpan</button>
										</td>
									</tr>
								</tbody>
							</table>
							<hr />
						 </div>
					  </div>
		}
		else {
			vdafkel = null;
		}

		return (
			<div className="container-fluid">
				<Subjudul judul="Daftar Keluarga" page="Keluarga" />
				<div className="row justify-content-center mt-3">
					<div className="col-12">
						<table className="table table-sm">
							<tbody>
								<tr>
									<td className="table-info">No. Anggota:</td>
									<td className="bg-success text-light" style={{fontSize:"14pt"}}>{this.state.dAngg.nomor_anggota}</td>
								</tr>
								<tr>
									<td className="table-info">Callsign Delta:</td>
									<td className="bg-success text-light" style={{fontSize:"14pt"}}><strong>{this.state.dAngg.callsign_delta}</strong></td>
								</tr>
								<tr>
									<td className="table-info">Nama Lengkap:</td>
									<td className="bg-success text-light"><strong>{this.state.dAngg.nama_lengkap}</strong></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<hr />
				{vdafkel}
				<div className="row justify-content-center">
					<div className="col-12">
						<div className="form-group">
							<label htmlFor="kodeklg">Nomor ID Keluarga:</label>
							<input type="text" id="kodeklg" className="form-control" value={this.state.kodeKeluarga} readOnly={true} />
						</div>
						<div className="form-group">
							<label htmlFor="nama">Nama Lengkap:</label>
							<input type="text" id="nama" className="form-control" value={this.state.namaLengkap} onChange={(e)=>this.isiForm('namaLengkap', e)} />
						</div>
						<div className="form-group">
							<label htmlFor="jkel">Jenis Kelamin:</label>
							<select id="jkel" className="form-control" value={this.state.jenisKelamin} onChange={(e)=>this.isiForm('jenisKelamin', e)}>
								<option value="">&nbsp;</option>
								<option value="L">Laki-Laki</option>
								<option value="P">Perempuan</option>
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="stkel">Status Keluarga:</label>
							<select className="form-control" id="stkel" value={this.state.statusKel} onChange={(e)=>this.isiForm('statusKel', e)}>
								{this.state.dStatus.map((ds, index) =>
									<option key={index} value={ds.kode}>{ds.deskripsi}</option>
								)}
							</select>
						</div>
						<div className="form-group text-right">
							<button className="btn btn-danger mr-2" onClick={this.hapusKeluarga}>Hapus</button>
							<button className="btn btn-primary" onClick={this.tbhKor}>Tambah/Koreksi</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
        server: state.server,
        kepala: state.kepala,
		nomoranggota: state.nomoranggota
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Frmkeluarga);
