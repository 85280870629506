import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

class Frmkoriuran extends Component {
	constructor(props) {
		super(props);
		this.state={deskripsi:'', tarif:0, periode:'', dper:[]};
	}

	daftarPeriode = () => {
		let svr = this.props.server + "/ciuran/res_daftarperiode";
		Axios.get(svr).then(
			(res)=>{
				if (res.data.kode==='00') {
					this.setState({dper:res.data.isi});
				}
				else {
					alert("Daftar Periode:\n" + res.data.pesan);
					this.props.gantiHalaman('KoreksiIuran');
				}
			}
		).catch((error) => {
			alert("Terjadi Error Daftar Periode Iuran:\n" + error);
			this.props.gantiHalaman('KoreksiIuran');
		});
	}

	isiForm = () => {
		let svr = this.props.server + "/ciuran/res_detiliuran";
		Axios.post(svr, {kode:this.props.kodeiuran}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({deskripsi:res.data.isi.deskripsi,
								   tarif:res.data.isi.tarif,
								   periode:res.data.isi.periode
								 });
				}
				else {
					alert("Detil Iuran:\n" + res.data.pesan);
					this.props.gantiHalaman('KoreksiIuran');
				}
			}
		).catch((error) => {
			alert("Terjadi Error:\n" + error);
			this.props.gantiHalaman('KoreksiIuran');
		});
	}

	doBatal = () => {
		this.props.gantiHalaman('KoreksiIuran');
	}

	nilaiIsian = (isian, e) => {
		this.setState({[isian] : e.target.value});
	}

	simpanEdit = () => {
		let svr = this.props.server + "/ciuran/res_koriuran";
		var konfir = window.confirm("Apakah Data Iuran Sudah Benar?");
		if (konfir) {
			Axios.post(svr, {kode:this.props.kodeiuran,
							 deskripsi:this.state.deskripsi,
							 tarif:this.state.tarif,
							 periode:this.state.periode,
							 useraktif:reactLocalStorage.get('pengguna')
						 }, this.props.kepala).then(
					(res) => {
						if (res.data.kode==='00') {
							alert("Koreksi Iuran Berhasil");
							this.props.gantiHalaman('KoreksiIuran');
						}
						else {
							alert("Terjadi Kesalahan Penyimpanan:\n" + res.data.pesan);
						}
					}
				).catch((error)=>{
					alert("Terjadi Error Simpan Koreksi:\n" + error);
				});
		}
	}

	componentDidMount() {
		this.isiForm();
		this.daftarPeriode();
	}
	render() {
		return(
			<div className="container-fluid">
				<Subjudul judul="Form Koreksi Iuran" page="KoreksiIuran" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="form-group">
							<label htmlFor="kode">Kode:</label>
							<input id="kode" type="text" className="form-control" value={this.props.kodeiuran} readOnly={true}/>
						</div>
						<div className="form-group">
							<label htmlFor="deskripsi">Deskripsi:</label>
							<input id="deskripsi" type="text" className="form-control" value={this.state.deskripsi} onChange={(e)=>this.nilaiIsian('deskripsi', e)}/>
						</div>
						<div className="form-group">
							<label htmlFor="tarif">Tarif:</label>
							<div className="input-group">
								<div className="input-group-prepend">
									<span className="input-group-text">Rp.</span>
								</div>
								<input id="tarif" type="text" className="form-control" value={this.state.tarif} onChange={(e)=>this.nilaiIsian('tarif', e)}/>
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="periode">Periode:</label>
							<select id="periode" className="form-control" value={this.state.periode} onChange={(e)=>this.nilaiIsian('periode', e)}>
								{this.state.dper.map((dp, index) =>
									<option key={index} value={dp.kode}>{dp.deskripsi}</option>
								)}
							</select>
						</div>
						<div className="form-group text-right">
							<button className="btn btn-secondary mr-2" onClick={(e)=>this.doBatal}>Batal</button>
							<button className="btn btn-primary" onClick={this.simpanEdit}>Simpan</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala,
		kodeiuran:state.kodeiuran
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Frmkoriuran);
