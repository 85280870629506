import React, {Component} from "react";
import Subjudul from './subjudul';
import Axios from 'axios';
import {connect} from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';
import Logoorari from '../gbr/logoorari.jpg';
import Logorapi from '../gbr/logorapi.png';
import Logodelta from '../gbr/dirgantara192.png';

class Frmkoranggota extends Component {
	constructor(props) {
		super(props);
		this.state = {nomorAnggota:'',
					  namaLengkap:'',
					  mobile:'',
					  callsignDelta:'',
					  callsignOrari:'',
					  callsignRapi:'',
					  namaAyahKd:'',
					  namaIbuKd:'',
					  namaAyahMr:'',
					  namaIbuMr:'',
					  tempatLahir:'',
					  tglLahir:'',
					  alamat:'',
					  kota:'',
					  nomorKTP: '',
					  nomorKK:'',
					  jenisKelamin:''
				  };
	}

	ambilData = () => {
		let svr = this.props.server + "/canggota/res_detilanggota";
		Axios.post(svr, {nomor_anggota:this.props.nomoranggota}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({nomorAnggota:res.data.isi.nomor_anggota,
								   namaLengkap:res.data.isi.nama_lengkap,
								   jenisKelamin:res.data.isi.jenis_kelamin,
								   tempatLahir:res.data.isi.tempat_lahir,
								   tglLahir:res.data.isi.tgl_lahir,
								   alamat:res.data.isi.alamat,
								   kota:res.data.isi.kota,
								   mobile:res.data.isi.mobile,
								   callsignDelta:res.data.isi.callsign_delta,
								   callsignOrari:res.data.isi.callsign_orari,
								   callsignRapi:res.data.isi.callsign_rapi,
								   namaAyahKd:res.data.isi.nama_ayah_kd,
								   namaIbuKd:res.data.isi.nama_ibu_kd,
								   namaAyahMr:res.data.isi.nama_ayah_mr,
								   namaIbuMr:res.data.isi.nama_ibu_mr,
								   nomorKTP:res.data.isi.nomor_ktp,
								   nomorKK:res.data.isi.nomor_kk
								  });
				}
				else {
					alert("Terjadi Kesalahan: \n" + res.data.pesan);
				}
			}
		).catch((error) => {
			alert("Terjadi Error: \n" + error);
		});
	}

	isiForm = (namaForm, e) => {
		this.setState({[namaForm]:e.target.value});
	}

	uploadFoto = (e) => {
		let svr = this.props.server + "/canggota/terima_uploadfoto";
		let vnamafile = e.target.files[0].name;
		let ekstensi = vnamafile.substr(vnamafile.length - 3);
		let nf = 'tmp_' + reactLocalStorage.get('pengguna') + '_.' + ekstensi

		var fdata = new FormData();
		this.setState({foto: nf});
		fdata.append('berkas', e.target.files[0], e.target.files[0].name);
		fdata.append('nama_file', nf);
		Axios.post(svr, fdata, {}).then(
			(res) => {
				if (res.data.kode==='00') {
					alert(res.data.pesan);
				}
			}
		).catch((error) => {
			alert("Terjadi Error:\n", error);
		});
	}

	simpanData = () => {
		let svr = this.props.server + "/canggota/res_koranggota";
		let vdata = {nomor_anggota:this.state.nomorAnggota,
					 nama_lengkap:this.state.namaLengkap,
					 jenis_kelamin:this.state.jenisKelamin,
					 tempat_lahir:this.state.tempatLahir,
					 tgl_lahir:this.state.tglLahir,
					 alamat:this.state.alamat,
					 kota:this.state.kota,
					 mobile:this.state.mobile,
					 nomor_ktp:this.state.nomorKTP,
					 nomor_kk:this.state.nomorKK,
					 cs_dirgantara:this.state.callsignDelta,
					 cs_orari:this.state.callsignOrari,
					 cs_rapi:this.state.callsignRapi,
					 ayah_kd:this.state.namaAyahKd,
					 ibu_kd:this.state.namaIbuKd,
					 ayah_mr:this.state.namaAyahMr,
					 ibu_mr:this.state.namaIbuMr,
					 foto:this.state.foto,
					 useraktif:reactLocalStorage.get('pengguna')
					};
		var konfir = window.confirm("Apakah Data Sudah Benar?");
		if (konfir) {
			Axios.post(svr, vdata, this.props.kepala).then(
				(res) => {
					if (res.data.kode==='00') {
						alert("Data Anggota BERHASIL DIKOREKSI");
						this.props.gantiHalaman('Anggota');
					}
					else {
						alert("Terjadi Kesalahan: \n" + res.data.pesan);
					}
				}
			).catch((error) => {
				alert("Terjadi Error: \n", error);
			})
		}
	}

	tombolBatal = () => {
		var konfir = window.confirm("Apakah Anda Yakin Ingin Membatalkan Koreksi Anggota");
		if (konfir) {
			this.props.gantiHalaman('KoreksiAnggota');
		}
	}

	componentDidMount() {
		this.ambilData();
	}

	render() {
		return (
			<div className="container-fluid">
				<Subjudul judul="Koreksi Data Anggota" page="KoreksiAnggota" />
				<div className="ros justify-content-center mt-5">
					<div className="col-12">
						<div className="form-group">
							<label htmlFor="no_anggota">Nomor Anggota:</label>
							<input type="text" id="no_anggota" className="form-control" value={this.state.nomorAnggota} readOnly={true}/>
						</div>
						<div className="form-group">
							<label htmlFor="namalengkap">Nama Lengkap:</label>
							<input type="text" id="namalengkap" className="form-control" value={this.state.namaLengkap} onChange={(e)=>this.isiForm('namaLengkap', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="jnskel">Jenis Kelamin:</label>
							<select id="jnskel" className="form-control" value={this.state.jenisKelamin} onChange={(e)=>this.isiForm('jenisKelamin', e)}>
								<option value="">&nbsp;</option>
								<option value="L">Laki-Laki</option>
								<option value="P">Perempuan</option>
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="tptlahir">Tempat Lahir:</label>
							<input type="text" id="tptlahir" className="form-control" value={this.state.tempatLahir} onChange={(e)=>this.isiForm('tempatLahir', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="tgllahir">Tanggal Lahir:</label>
							<input type="date" id="tgllahir" className="form-control" value={this.state.tglLahir} onChange={(e)=>this.isiForm('tglLahir', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="alamat">Alamat:</label>
							<input type="text" id="alamat" className="form-control" value={this.state.alamat} onChange={(e)=>this.isiForm('alamat', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="kota">Kota Domisili:</label>
							<input type="text" id="kota" className="form-control" value={this.state.kota} onChange={(e)=>this.isiForm('kota', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="nomorhp">Nomor HP / WA:</label>
							<input type="text" id="nomorhp" className="form-control" value={this.state.mobile} onChange={(e)=>this.isiForm('mobile', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="nomorktp">Nomor KTP:</label>
							<input type="text" id="nomorktp" className="form-control" value={this.state.nomorKTP} onChange={(e)=>this.isiForm('nomorKTP', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="nomorkk">Nomor KK:</label>
							<input type="text" id="nomorkk" className="form-control" value={this.state.nomorKK} onChange={(e)=>this.isiForm('nomorKK', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="csdelta">Callsign Delta <img src={Logodelta} alt="Logo Delta" width="10%" height="10%" />:</label>
							<input type="text" id="csdelta" className="form-control" value={this.state.callsignDelta} onChange={(e)=>this.isiForm('callsignDelta', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="csorari">Callsign ORARI <img src={Logoorari} alt="Logo ORARI" width="10%" height="10%" />:</label>
							<input type="text" id="csorari" className="form-control" value={this.state.callsignOrari} onChange={(e)=>this.isiForm('callsignOrari', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="csrapi">Callsign RAPI <img src={Logorapi} alt="Logo RAPI" width="10%" height="10%" />:</label>
							<input type="text" id="csrapi" className="form-control" value={this.state.callsignRapi} onChange={(e)=>this.isiForm('callsignRapi', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="ayahkd">Nama Ayah Kandung:</label>
							<input type="text" id="ayahkd" className="form-control" value={this.state.namaAyahKd} onChange={(e)=>this.isiForm('namaAyahKd', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="ibukd">Nama Ibu Kandung:</label>
							<input type="text" id="ibukd" className="form-control" value={this.state.namaAyahKd} onChange={(e)=>this.isiForm('namaIbuKd', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="ayahmr">Nama Ayah Mertua:</label>
							<input type="text" id="ayahmr" className="form-control" value={this.state.namaAyahMr} onChange={(e)=>this.isiForm('namaAyahMr', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="ibumr">Nama Ibu Mertua:</label>
							<input type="text" id="ibumr" className="form-control" value={this.state.namaIbuMr} onChange={(e)=>this.isiForm('namaIbuMr', e)}  />
						</div>
						<div className="form-group">
							<label htmlFor="foto">Foto Anggota:</label>
							<input type="file" id="foto" className="form-control" onChange={(e)=>this.uploadFoto(e)}/>
						</div>
						<div className="form-group text-right">
							<button className="btn btn-secondary mr-2" onClick={this.tombolBatal}>Batal</button>
							<button className="btn btn-primary" onClick={this.simpanData}>Simpan</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
        server: state.server,
        kepala: state.kepala,
		nomoranggota: state.nomoranggota
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Frmkoranggota);
