import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

class Tglawalbayar extends Component {
	constructor(props) {
		super(props);
		this.state={dAng:[], cari:'', diuran:[], kodeiuran:'', tglAwal:''};
	}

	isiIuran = (e) => {
		this.setState({kodeiuran:e.target.value});
	}

	isiCari = (e) => {
		this.setState({cari:e.target.value});
	}

	gantiTgl = (e) => {
		this.setState({tglAwal:e.target.value})
	}

	dftIuran = () => {
		let svr = this.props.server + "/ciuran/res_daftariuran";
		Axios.post(svr, {cari:null}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({diuran:res.data.isi});
				}
				else {
					alert("Terjadi Kesalahan Daftar Iuran:\n" + res.data.pesan);
					this.props.gantiHalaman("Iuran");
				}
			}
		).catch((error) => {
			alert("Terjadi Error Daftar Iuran: \n" + error);
			this.props.gantiHalaman("Iuran");
		});
	}

	isiDaftar = () => {
		let svr = this.props.server + "/ciuran/res_dfttglawalbyr";
		Axios.post(svr, {cari:this.state.cari, kode:this.state.kodeiuran}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({dAng:res.data.isi});
				}
				else {
					alert("Terjadi Kesalahan:\n" + res.data.pesan);
				}
			}
		).catch((error)=>{
			alert("Terjadi Error: \n" + error);
		});
	}

	setTglAwal = (noAng, ltglAwal, kdIuran) => {
		let svr = this.props.server + "/ciuran/res_settglawal";
		var konfir = window.confirm("Apakah Tanggal Sudan Benar?");
		if (konfir) {
			Axios.post(svr, {nomor_anggota:noAng,
							 tgl_awal:ltglAwal,
							 useraktif:reactLocalStorage.get('pengguna'),
							 kode:kdIuran
						 }, this.props.kepala).then(
							 (res) => {
								 if (res.data.kode==='00') {
									 alert("Tanggal Awal Bayar BERHASIL DISETTING");
									 this.setState({dAng:[]});
								 }
								 else {
									 alert("Terjadi Kesalahan Setting Tgl. Awal Bayar:\n" + res.data.pesan);
								 }
							 }
						 )
		}
	}



	componentDidMount() {
		this.dftIuran();
	}

	render() {
		let vdaftar;
		vdaftar = this.state.dAng.length > 0 ?
				  <div className="row justify-content-center mt-5">
				  	<div className="col-12">
						{this.state.dAng.map((da, index) =>
							<table className="table table-sm" key={index}>
								<tbody>
									<tr>
										<td className="table-info">No. Anggota:</td>
										<td className="bg-primary text-light">{da.nomor_anggota}</td>
									</tr>
									<tr>
										<td className="table-info">Callsign:</td>
										<td className="bg-primary text-light" style={{fontSize:"14pt"}}>{da.callsign_delta}</td>
									</tr>
									<tr>
										<td className="table-info">Nama Lengkap:</td>
										<td className="bg-primary text-light">{da.nama_lengkap}</td>
									</tr>
									<tr>
										<td className="table-info">Tgl. Awal Tagihan:</td>
										<td className="bg-primary text-light">
											<input type="date" className="form-control" value={da.tgl_mulai_tagihan} onChange={(e)=>this.gantiTgl(e)}/>
										</td>
									</tr>
									<tr>
										<td className="table-info">Status:</td>
										<td className="bg-primary text-light"><strong>{da.status_aktif ? 'Aktif' : 'Tidak Aktif'}</strong></td>
									</tr>
									<tr>
										<td colSpan="2" className="text-right">
											<button className="btn btn-success" onClick={(e)=>this.setTglAwal(da.nomor_anggota, this.state.tglAwal, this.state.kodeiuran)}>Atur Tgl. Awal Bayar</button>
										</td>
									</tr>
								</tbody>
							</table>
						)}
					</div>
				  </div> : null;

		return (
			<div className="container-fluid">
				<Subjudul judul="Set Tgl. Awal Bayar" page="Iuran" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text">Jenis Iuran</span>
							</div>
							<select className="form-control" onChange={(e)=>this.isiIuran(e)}>
								<option value="">&nbsp;</option>
								{this.state.diuran.map((di, index)=>
									<option key={index} value={di.kode}>{di.deskripsi}</option>
								)}
							</select>
						</div>
					</div>
				</div>
				<div className="row justify-content-center mt-3">
					<div className="col-12">
						<div className="input-group">
							<input type="text" placeholder="No. Anggota/Callsign/Nama" className="form-control" onChange={(e)=>this.isiCari(e)}/>
							<div className="input-group-append">
								<button className="btn btn-primary" onClick={this.isiDaftar}>Cari</button>
							</div>
						</div>
					</div>
				</div>
				{vdaftar}
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}})
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Tglawalbayar);
