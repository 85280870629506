import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";

class Hakakses extends Component {
    render() {
        return(
            <div className="container fluid">
                <Subjudul judul="Pengelolaan Hak Akses" page="Adm" />
                <div className="row justify-content-center" style={{marginTop:"50%"}}>
                    <div className="col-6">
                        <div className="card bg-danger text-white" onClick={(e)=>this.props.gantiHalaman('AksesKategori')}>
                            <div className="card-body text-center">
                                <h4>Akses Menu Utama</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card bg-warning text-white" style={{paddingTop:"15%", paddingBottom:"5%"}} onClick={(e)=>this.props.gantiHalaman('AksesFitur')}>
                            <div className="card-body text-center">
                                <h4>Akses Fitur</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const petaGudangkeProp = (state) => {
    return {
        halaman: state.halaman
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Hakakses);
