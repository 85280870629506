import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

class Gantipassword extends Component {
	constructor(props) {
		super(props);
		this.state = {passlama:'',
					  passbaru:'',
					  passkonfir:''
					 };
	}

	isiData = (isian, e) => {
		this.setState({[isian]:e.target.value});
	}

	cekGanti = () => {
		let svr = this.props.server + '/cpengguna/res_chpass';
		var konfir = window.confirm("Apakah Password Sudah Benar?");
		if (konfir) {
			Axios.post(svr,
					   {passlama:this.state.passlama,
						passbaru:this.state.passbaru,
						passkonfir:this.state.passkonfir,
						useraktif:reactLocalStorage.get('pengguna')
					    },
					   this.props.kepala).then(
						   (response) => {
							   if (response.data.kode==='00') {
								   alert(response.data.pesan);
								   this.props.gantiHalaman('Adm');
							   }
							   else {
								   alert(response.data.pesan);
							   }
						   }
					   ).catch((error)=>{
						  alert("Terjadi Error: ", error);
					   });
		}
	}

	render() {
		return (
			<div className="container-fluid">
				<Subjudul judul="Ganti Password" page="Adm" />
				<div className="row justify-content-center mt-5">
					<div className="col-11">
						<div className="form-group">
							<input type="password" className="form-control form-control-lg" placeholder="Password Lama" onChange={(e)=>this.isiData('passlama', e)}/>
						</div>
						<div className="form-group">
							<input type="password" className="form-control form-control-lg" placeholder="Password Baru" onChange={(e)=>this.isiData('passbaru', e)}/>
						</div>
						<div className="form-group">
							<input type="password" className="form-control form-control-lg" placeholder="Ulangi Password Baru" onChange={(e)=>this.isiData('passkonfir', e)}/>
						</div>
					</div>
				</div>
				<div className="row justify-content-center mt-3">
					<div className="col-12 text-right">
						<button className="btn btn-lg btn-secondary mr-3" onClick={(e)=>this.props.gantiHalaman('Adm')}>Batal</button>
						<button className="btn btn-lg btn-primary" onClick={this.cekGanti}>Simpan</button>
					</div>
				</div>
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}}),
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Gantipassword);
