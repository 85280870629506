import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

class Hapusiuran extends Component {
	constructor(props) {
		super(props);
		this.state={cari:'', diuran:[]};
	}

	isiCari = (e) => {
		this.setState({cari:e.target.value});
	}

	doCari = () => {
		let svr = this.props.server + "/ciuran/res_daftariuran";
		Axios.post(svr, {cari:this.state.cari}, this.props.kepala).then(
			(res) => {
				if (res.data.kode==='00') {
					this.setState({diuran:res.data.isi});
				}
				else {
					alert("Terjadi Kesalahan Pencarian:\n" + res.data.pesan);
				}
			}
		).catch((error)=>{
			alert("Terjadi Error Pencarian:\n" + error);
		});
	}

	hapusIuran = (kodeIuran) => {
		let svr = this.props.server + "/ciuran/res_hapusiuran";
		var konfir = window.confirm("Apakah Anda Yakin Ingin Menghapus Iuran Ini?");
		if (konfir) {
			Axios.post(svr, {kode:kodeIuran, useraktif:reactLocalStorage.get('pengguna')}, this.props.kepala).then(
				(res) => {
					if (res.data.kode==='00') {
						alert("Jenis Iuran BERHASIL DIHAPUS!!!");
						this.props.gantiHalaman('JenisIuran');
					}
					else {
						alert("Jenis Iuran GAGAL DIHAPUS: \n" + res.data.pesan);
					}
				}
			).catch((error) => {
				alert("Terjadi Error: \n" + error);
			});
		}
	}

	render() {
		let vdaftar;
		vdaftar = this.state.diuran.length > 0 ?
				  <div className="row justify-content-center mt-3">
				  	<div className="col-12">
						{this.state.diuran.map((di, index) =>
							<table className="table table-sm" key={index}>
								<tbody>
									<tr>
										<td className="table-danger">Kode:</td>
										<td className="bg-danger text-light"><strong>{di.kode}</strong></td>
									</tr>
									<tr>
										<td className="table-danger">Deskripsi:</td>
										<td className="bg-danger text-light"><strong>{di.deskripsi}</strong></td>
									</tr>
									<tr>
										<td className="table-danger">Tarif:</td>
										<td className="bg-danger text-light"><strong>Rp. {di.tarif}</strong></td>
									</tr>
									<tr>
										<td className="table-danger">Tarif:</td>
										<td className="bg-danger text-light"><strong>{di.periode_d}</strong></td>
									</tr>
									<tr>
										<td colSpan="2" className="text-right">
											<button className="btn btn-danger" onClick={(e)=>this.hapusIuran(di.kode)}>Hapus</button>
										</td>
									</tr>
								</tbody>
							</table>
						)}
					</div>
				  </div> : null;
		return(
			<div className="container-fluid">
				<Subjudul judul='Hapus Iuran' page="JenisIuran" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="input-group">
							<input type="text" className="form-control" placeholder="Deskripsi Iuran" />
							<div className="input-group-append">
								<button className="btn btn-danger" onClick={this.doCari}>Cari</button>
							</div>
						</div>
					</div>
				</div>
				{vdaftar}
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
		isiKodeIuran: (kode) => aksi({type:'ISI_KODE_IURAN', payload:{kodeiuran:kode}}),
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}})
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Hapusiuran);
