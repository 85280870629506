import React, {Component} from "react";
import Subjudul from './subjudul';
import {connect} from "react-redux";
import Axios from "axios";

class Infoiuran extends Component {
	constructor(props) {
		super(props);
		this.state={cari:'', diuran:[]};
	}

	isiCari = (e) => {
		this.setState({cari:e.target.value});
	}

	doCari = () => {
		let svr = this.props.server + "/ciuran/res_daftariuran";
		Axios.post(svr, {cari:this.state.cari}, this.props.kepala).then(
			(res)=> {
				if (res.data.kode==='00') {
					this.setState({diuran:res.data.isi});
				}
				else {
					alert("Terjadi Kesalahan:\n" + res.data.pesan);
				}
			}
		).catch((error)=> {
			alert('Terjadi Error:\n' + error);
		});
	}

	tampilDetil = (kode) => {
		this.props.isiKodeIuran(kode);
		this.props.gantiHalaman('DetilIuran');
	}

	render() {
		let vdaftar;

		vdaftar = this.state.diuran.length > 0 ?
				  <div className="row justify-content-center mt-3">
				  	<div className="col-12">
						{this.state.diuran.map((di, index) =>
						<table className="table table-sm" key={index}>
							<tbody>
								<tr>
									<td className="table-info">Kode:</td>
									<td className="bg-success text-light">{di.kode}</td>
								</tr>
								<tr>
									<td className="table-info">Deskripsi:</td>
									<td className="bg-success text-light">{di.deskripsi}</td>
								</tr>
								<tr>
									<td colSpan="2" className="text-right">
										<button className="btn btn-outline-info" onClick={(e)=>{this.tampilDetil(di.kode)}}>Detil</button>
									</td>
								</tr>
							</tbody>
						</table>
						)}
					</div>
				  </div> : null;

		return (
			<div className="container-fluid">
				<Subjudul judul="Informasi Jenis Iuran" page="JenisIuran" />
				<div className="row justify-content-center mt-5">
					<div className="col-12">
						<div className="input-group">
							<input type="text" className="form-control" placeholder="Deskripsi Iuran" />
							<div className="input-group-append">
								<button className="btn btn-primary" onClick={this.doCari}>Cari</button>
							</div>
						</div>
					</div>
				</div>
				{vdaftar}
			</div>
		)
	}
}

const petaGudangkeProp = (state) => {
    return {
		server: state.server,
		kepala: state.kepala
    }
}

const petaAksikeProp = (aksi) => {
    return {
		isiKodeIuran: (kode) => aksi({type:'ISI_KODE_IURAN', payload:{kodeiuran:kode}}),
        gantiHalaman: (hal) => aksi({type:'GANTI_HALAMAN', payload:{halaman:hal}})
    }
}

export default connect(petaGudangkeProp, petaAksikeProp)(Infoiuran);
